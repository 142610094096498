import { Component, OnInit,AfterViewInit } from '@angular/core';
import { IDGeneratorService } from '../../../services';
import { GraphService } from '../graph.service';
import { GraphCore } from '../graph.core';

import { SessionObject, ProjectUtils, UrlConstants } from '../../../utility';
declare var Highcharts;
@Component({
  selector: 'app-metric-typepie3-d',
  templateUrl: './metric-typepie3-d.component.html',
  styleUrls: ['./metric-typepie3-d.component.css']
})

export class MetricTypepie3DComponent extends GraphCore implements AfterViewInit {
  NAME_CHART = 'semi-cicle-donut';
  queryString : String;
  finaldata = [];

  constructor(
    protected idGeneratorService: IDGeneratorService,
    protected graphService: GraphService
  ) {
    super(graphService, idGeneratorService);
  }

  downloadMetricChart3d(){
    const childdetail = ProjectUtils.getSessionObject().consortiaChildUserID;
    if(childdetail != 'consortiaAdmin'){
          this.queryString = childdetail;
    }else{
          this.queryString = ProjectUtils.getSessionObject().userDetails.userID;
    }
    
    const monthLive= ProjectUtils.getSessionObject().liveMonth;
    
        const url = `${UrlConstants.DASHBOARD_GRAPH_MONTH_WISE_METRIC_SUM_CSV}?user_id=${this.queryString}&month=${monthLive}`;
        console.log('Download_URL', url);
        ProjectUtils.downloadGET(url);
    
      }


  initChart(resultdata: any) {
    //debugger



    resultdata.forEach(element => {
      element.name = element.key;
      element.y = parseFloat(element.value);
      delete element.key;
      delete element.value;
      // console.log(element);
    });

    Highcharts.chart(this.id, {
      chart: {
        type: 'pie',
        options3d: {
          enabled: true,
          alpha: 45,
          beta: 0
        }
      },

      title: {
        text: '<span style="font-size:15px;">YTD METRIC WISE CONTRIBUTION(%)</span>',
        align: 'left',
        style: {
          fontSize: '24px'
        }
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          depth: 35,
          dataLabels: {
            enabled: true,
            format: '{point.name}'
          }
        }
      },
      series: [{
        type: 'pie',
        name: 'Count',
        // data: [
        //   { name: 'Firefox', y: 45.0 },
        //   ['IE', 26.8],
        //   {
        //     name: 'Chrome',
        //     y: 12.8,
        //     sliced: true,
        //     selected: true
        //   },
        //   ['Safari', 8.5],
        //   ['Opera', 6.2],
        //   ['Others', 0.7]
        // ]
        data: resultdata
      }],
      credits: {
        enabled: false
      }
    });
  }


}
