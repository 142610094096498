import {
  Component, OnInit, AfterViewInit
} from '@angular/core';

import { IDGeneratorService } from '../../../services';
import { GraphService } from '../graph.service';
import { GraphCore } from '../graph.core';

declare var Highcharts;

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.css']
})
export class PieChartComponent extends GraphCore implements AfterViewInit {
  NAME_CHART = 'pie-chart';
  constructor(
    protected idGeneratorService: IDGeneratorService,
    protected graphService: GraphService
  ) {
    super(graphService, idGeneratorService);
    
  }
title = [];
axis=[];
  initChart(resultdata: any) {
    // Build the 
    resultdata.forEach(element => {
this.title.push(element['name']);
this.axis.push(element['y']);
    });

    var pieColors = (function () {
      var colors = [],
          base = Highcharts.getOptions().colors[0],
          i;
     
      for (i = 0; i < 10; i += 1) {
          // Start out with a darkened base color (negative brighten), and end
          // up with a much brighter color
          colors.push(Highcharts.Color(base).brighten((i - 3) / 7).get());
      }
      return colors;
     }());
    Highcharts.chart(this.id, {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },

      title: {
        text: '<span style="font-size:15px;">Usages Division</span>',
        align: 'center',
        style: {
          fontSize: '24px'
        }
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          colors: pieColors,
          dataLabels: {
            enabled: false
          },
          showInLegend: true
        }
      },
      series: [{
        name: 'Usages %',
        colorByPoint: true,
        data: [{
          name: this.title[0],
          y: this.axis[0],
          sliced: true,
          selected: true
        }, {
          name: this.title[1],
          y: this.axis[1],
         
        }, {
          name: this.title[2],
          y: this.axis[2],
         
        }]
      }],
      credits: {
        enabled: false
      }
    });




  }

}
