import { Component, OnInit, AfterViewInit } from '@angular/core';
import { IDGeneratorService } from '../../../services';
import { GraphService } from '../graph.service';
import { GraphCore } from '../graph.core';
import { SessionObject, ProjectUtils, UrlConstants } from '../../../utility';
declare var Highcharts;

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.css']
})
export class LineChartComponent extends GraphCore implements AfterViewInit {
  NAME_CHART = 'line-chart';
  queryString:String;
  constructor(
    protected idGeneratorService: IDGeneratorService,
    protected graphService: GraphService
  ) {
    super(graphService, idGeneratorService);
  }





  downloadLineChart(){
    
    const childdetail = ProjectUtils.getSessionObject().consortiaChildUserID;
    if(childdetail != 'consortiaAdmin'){
          this.queryString = childdetail;
    }else{
          this.queryString = ProjectUtils.getSessionObject().userDetails.userID;
    }
    
    
    const monthLive= ProjectUtils.getSessionObject().liveMonth;
        const url = `${UrlConstants.DASHBOARD_GRAPH_PLATFORM_PLATFORM_AVERAGE_FULLTEXT_VIEWCSV}?user_id=${this.queryString}&token='average'`;
        console.log('Download_URL', url);
        ProjectUtils.downloadGET(url);
    
      }
    

  initChart(resultdata: any) {
    const categories = [];
    const Fulltextdata = [];
    const prodName = [];
    resultdata.forEach(element => {
      categories.push(element['count']);
      Fulltextdata.push(element['YTD']);
      prodName.push(element['mpsCollectionPlatform']);
    });


    //debugger
    Highcharts.chart(this.id, {
      chart: {
        type: 'line'
      },

      title: {
        text: '<span style="font-size:15px;">Top Platform:Average Full-Text Views</span>',
        align: 'left',
        style: {
          fontSize: '24px'
        }
      },
      subtitle: {
        text: ''
      },
      xAxis: {
        //  categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        categories: prodName

      },
      yAxis: {
        title: {
          text: 'Successful Full-Text Requests'
        }
      },
      plotOptions: {

        series: [{
          data: prodName
        }],
        line: {
          dataLabels: {
            enabled: true

          },
          enableMouseTracking: true

        }

      },
      series: [{
        name: 'Average Full-Text Views',
        // data: [7.0, 6.9, 9.5, 14.5, 18.4, 21.5, 25.2, 26.5, 23.3, 18.3, 13.9, 9.6]
        data: Fulltextdata
      }],
      credits: {
        enabled: false
      }
    });
    //end of chart2
  }

}
