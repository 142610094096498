import { Component, OnInit } from '@angular/core';
import { IDGeneratorService } from '../../../services';
import { GraphService } from '../graph.service';
import { GraphCore } from '../graph.core';
import { SessionObject, ProjectUtils, UrlConstants } from '../../../utility';

declare var Highcharts;

@Component({
  selector: 'app-bi-bar-vertical',
  templateUrl: './bi-bar-vertical.component.html',
  styleUrls: ['./bi-bar-vertical.component.css']
})
export class BiBarVerticalComponent extends GraphCore {
  NAME_CHART = 'bar-chart';
  queryString:String;
  constructor(
    protected idGeneratorService: IDGeneratorService,
    protected graphService: GraphService
  ) {
    super(graphService, idGeneratorService);

  }
  
  downloadAccessDenialChart(){
    const childdetail = ProjectUtils.getSessionObject().consortiaChildUserID;
    if(childdetail != 'consortiaAdmin'){
          this.queryString = childdetail;
    }else{
          this.queryString = ProjectUtils.getSessionObject().userDetails.userID;
    }
    
    
    const monthLive= ProjectUtils.getSessionObject().liveMonth;
        const url = `${UrlConstants.DASHBOARD_GRAPH_JOURNAL_TOP_ACCESS_DENAILCSV}?user_id=${this.queryString}`;
        console.log('Download_URL', url);
        ProjectUtils.downloadGET(url);
    
      }
  initChart(resultdata: any) {

    // console.log('dataaaaaaaa', resultdata['journal']);
    const resultcategories = resultdata['journal'];
    const resultseriesdata1 = resultdata['exceeded'];
    const resultseriesdata2 = resultdata['licenced'];
    console.log('dataaaaaaaa', resultcategories, resultseriesdata1, resultseriesdata2);
    Highcharts.chart(this.id, {
      chart: {
        type: 'column'
      },
      title: {
        text: '<span style="font-size:15px;">Top Platforms by Access Denial Journals Count</span>',
        align: 'left',
        style: {
          fontSize: '24px'
        }
      },
      xAxis: {
        // categories: ['Project MUSE', 'Cambridge Journals Online', 'Institute of Electrical and Electronics Engineers', 'OvidSP', 'JSTOR']
        categories: resultcategories
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Access Denied to Full-text Articles'
        }
      },
      tooltip: {
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
        shared: true
      },
      plotOptions: {
        column: {
          stacking: 'normal'
        }

      },
      series: [{
        name: 'Access denied: concurrent/simultaneous user licence limit exceeded',
        data: resultseriesdata1,
        color: '#8abe6e'
      }, {
        name: 'Access denied: content item not licenced',
        data: resultseriesdata2,
        color: '#aacce7'
      }],
      credits: {
        enabled: false
      }
    });
  }

}
