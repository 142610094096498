import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import { SessionObject, ProjectUtils } from '../../utility';



@Injectable()
export class SessionExpGuard {

      constructor() {
      }




}

