import { Component, OnInit, AfterViewInit } from '@angular/core';
import { IDGeneratorService } from '../../../services';
import { GraphService } from '../graph.service';

import { SessionObject,ProjectUtils, UrlConstants } from '../../../utility';
import { GraphCore } from '../graph.core';

declare var Highcharts;
@Component({
  selector: 'app-drill-down-chart',
  templateUrl: './drill-down-chart.component.html',
  styleUrls: ['./drill-down-chart.component.css']
})


export class DrillDownChartComponent extends GraphCore implements AfterViewInit {
  NAME_CHART = 'drill-down-down-chart';
  queryString:String;
  constructor(
    protected idGeneratorService: IDGeneratorService,
    protected graphService: GraphService
  ) {
    super(graphService, idGeneratorService);

  }

  
  downloadTop10JournalTitlesChart(){
    const childdetail = ProjectUtils.getSessionObject().consortiaChildUserID;
    if(childdetail != 'consortiaAdmin'){
          this.queryString = childdetail;
    }else{
          this.queryString = ProjectUtils.getSessionObject().userDetails.userID;
    }
    
    
    const monthLive= ProjectUtils.getSessionObject().liveMonth;
        const url = `${UrlConstants.DASHBOARD_GRAPH_YEAR_TOP_JOURNALCSV}?user_id=${this.queryString}&month=${monthLive}`;
        console.log('Download_URL', url);
        ProjectUtils.downloadGET(url);
    
      }


  JournalStats= [];
  ytdStats=[];
  aprStats=[];
  augStats=[];
  decStats=[];
  janStats=[];
  julStats=[];
  junStats=[];
  marStats=[];
  mayStats=[];
  novStats=[];
  octStats=[];
  septStats=[];
  febStats=[];
  monthData =[];
  initChart(resultdata: any) {
    resultdata.forEach(element => {
this.JournalStats.push(element['journalTitle']);
this.janStats.push(element['janStats']);
this.febStats.push(element['febStats']);
this.marStats.push(element['marStats']);
this.aprStats.push(element['aprStats']);
this.mayStats.push(element['mayStats']);
this.junStats.push(element['junStats']);
this.julStats.push(element['julStats']);
this.augStats.push(element['augStats']);
this.septStats.push(element['septStats']);
this.octStats.push(element['octStats']);
this.novStats.push(element['novStats']);
this.decStats.push(element['decStats']);
this.ytdStats.push(element['ytd']);

      });

     // this.sessionObject.liveMonth;
 
     this.monthData.push('Jan',this.janStats[0]);
     this.monthData.push('Feb',this.janStats[0]);
     this.monthData.push('Mar',this.janStats[0]);
     this.monthData.push('Apr',this.janStats[0]);
     this.monthData.push('May',this.janStats[0]);
     this.monthData.push('Jun',this.janStats[0]);
     this.monthData.push('July',this.janStats[0]);
     this.monthData.push('Aug',this.janStats[0]);
     this.monthData.push('Sep',this.janStats[0]);
     this.monthData.push('OCt',this.janStats[0]);
     this.monthData.push('Nov',this.janStats[0]);
     this.monthData.push('Dec',this.janStats[0]);
    // Create the chart
    Highcharts.chart(this.id, {
      
      chart: {
        type: 'column',
      },
      title: {
        text: 'Top 10 Journal Titles'
      },
      xAxis: {
        type: 'category'
      },

      legend: {
        enabled: false
      },

      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true
          }
        }
      },

      series: [{
        name: 'Journals',
        colorByPoint: true,
        data: [{
          name: this.JournalStats[0],
          y: this.ytdStats[0],
          drilldown: this.JournalStats[0]
        }, {
          name: this.JournalStats[1],
          y: this.ytdStats[1],
          drilldown: this.JournalStats[1]
        }, {
          name: this.JournalStats[2],
          y: this.ytdStats[2],
          drilldown: this.JournalStats[2]
        }, {
          name: this.JournalStats[3],
          y: this.ytdStats[3],
          drilldown: this.JournalStats[3]
        }, {
          name: this.JournalStats[4],
          y: this.ytdStats[4],
          drilldown: this.JournalStats[4]
        }, {
          name: this.JournalStats[5],
          y: this.ytdStats[5],
          drilldown: this.JournalStats[5]
        }, {
          name: this.JournalStats[6],
          y: this.ytdStats[6],
          drilldown: this.JournalStats[6]
        }, {
          name: this.JournalStats[7],
          y: this.ytdStats[7],
          drilldown: this.JournalStats[7]
        }, {
          name: this.JournalStats[8],
          y: this.ytdStats[8],
          drilldown: this.JournalStats[8]
        }, {
          name: this.JournalStats[9],
          y: this.ytdStats[9],
          drilldown: this.JournalStats[9]
        }]
      }],

      drilldown: {
        series: [
					
          {
          name: this.JournalStats[0],
          id: this.JournalStats[0],
          data: [['Jan',this.janStats[0]],['Feb',this.febStats[0]],['Mar',this.marStats[0]],['Apr',this.aprStats[0]],['May',this.mayStats[0]],['Jun',this.junStats[0]],['Jul',this.julStats[0]],['Aug',this.augStats[0]],['Sep',this.septStats[0]],['Oct',this.octStats[0]],['Nov',this.novStats[0]],['Dec',this.decStats[0]]]
        }, 
  
          {
          name: this.JournalStats[1],
          id: this.JournalStats[1],
          data: [['Jan',this.janStats[1]],['Feb',this.febStats[1]],['Mar',this.marStats[1]],['Apr',this.aprStats[1]],['May',this.mayStats[1]],['Jun',this.junStats[1]],['Jul',this.julStats[1]],['Aug',this.augStats[1]],['Sep',this.septStats[1]],['Oct',this.octStats[1]],['Nov',this.novStats[1]],['Dec',this.decStats[1]]]        }, 
  
          {
          name: this.JournalStats[2],
          id: this.JournalStats[2],
          data: [['Jan',this.janStats[2]],['Feb',this.febStats[2]],['Mar',this.marStats[2]],['Apr',this.aprStats[2]],['May',this.mayStats[2]],['Jun',this.junStats[2]],['Jul',this.julStats[2]],['Aug',this.augStats[2]],['Sep',this.septStats[2]],['Oct',this.octStats[2]],['Nov',this.novStats[2]],['Dec',this.decStats[2]]]        }, 
  
          {
          name: this.JournalStats[3],
          id: this.JournalStats[3],
          data: [['Jan',this.janStats[3]],['Feb',this.febStats[3]],['Mar',this.marStats[3]],['Apr',this.aprStats[3]],['May',this.mayStats[3]],['Jun',this.junStats[3]],['Jul',this.julStats[3]],['Aug',this.augStats[3]],['Sep',this.septStats[3]],['Oct',this.octStats[3]],['Nov',this.novStats[3]],['Dec',this.decStats[3]]]        }, 
  
          {
          name: this.JournalStats[4],
          id: this.JournalStats[4],
          data: [['Jan',this.janStats[4]],['Feb',this.febStats[4]],['Mar',this.marStats[4]],['Apr',this.aprStats[4]],['May',this.mayStats[4]],['Jun',this.junStats[4]],['Jul',this.julStats[4]],['Aug',this.augStats[4]],['Sep',this.septStats[4]],['Oct',this.octStats[4]],['Nov',this.novStats[4]],['Dec',this.decStats[4]]]        }, 
  
          {
          name: this.JournalStats[5],
          id: this.JournalStats[5],
          data: [['Jan',this.janStats[5]],['Feb',this.febStats[5]],['Mar',this.marStats[5]],['Apr',this.aprStats[5]],['May',this.mayStats[5]],['Jun',this.junStats[5]],['Jul',this.julStats[5]],['Aug',this.augStats[5]],['Sep',this.septStats[5]],['Oct',this.octStats[5]],['Nov',this.novStats[5]],['Dec',this.decStats[5]]]        }, 
  
          {
          name: this.JournalStats[6],
          id: this.JournalStats[6],
          data: [['Jan',this.janStats[6]],['Feb',this.febStats[6]],['Mar',this.marStats[6]],['Apr',this.aprStats[6]],['May',this.mayStats[6]],['Jun',this.junStats[6]],['Jul',this.julStats[6]],['Aug',this.augStats[6]],['Sep',this.septStats[6]],['Oct',this.octStats[6]],['Nov',this.novStats[6]],['Dec',this.decStats[6]]]        }, 
  
          {
          name: this.JournalStats[7],
          id: this.JournalStats[7],
          data: [['Jan',this.janStats[7]],['Feb',this.febStats[7]],['Mar',this.marStats[7]],['Apr',this.aprStats[7]],['May',this.mayStats[7]],['Jun',this.junStats[7]],['Jul',this.julStats[7]],['Aug',this.augStats[7]],['Sep',this.septStats[7]],['Oct',this.octStats[7]],['Nov',this.novStats[7]],['Dec',this.decStats[7]]]        }, 
  
          {
          name: this.JournalStats[8],
          id: this.JournalStats[8],
          data: [['Jan',this.janStats[8]],['Feb',this.febStats[8]],['Mar',this.marStats[8]],['Apr',this.aprStats[8]],['May',this.mayStats[8]],['Jun',this.junStats[8]],['Jul',this.julStats[8]],['Aug',this.augStats[8]],['Sep',this.septStats[8]],['Oct',this.octStats[8]],['Nov',this.novStats[8]],['Dec',this.decStats[8]]]
        }, 
  
          {
          name: this.JournalStats[9],
          id: this.JournalStats[9],
          data: [['Jan',this.janStats[9]],['Feb',this.febStats[9]],['Mar',this.marStats[9]],['Apr',this.aprStats[9]],['May',this.mayStats[9]],['Jun',this.junStats[9]],['Jul',this.julStats[9]],['Aug',this.augStats[9]],['Sep',this.septStats[9]],['Oct',this.octStats[9]],['Nov',this.novStats[9]],['Dec',this.decStats[9]]]
        },


        ]
      },
      credits: {
        enabled: false
      }
    });







  }

}
