import { Injectable } from '@angular/core';
import { Utils } from '../../../utility';

import { darkTheme, darkFontUrl, darkCssUrl } from './dark.theme';
import { lightTheme, lightFontUrl, lightCssUrl } from './light.theme';
import { defaultTheme, defaultCssUrl } from './default.theme';

declare var Highcharts;


@Injectable()

export class HighChartService {

      defaultOption: any;

      constructor() {
            this.defaultOption = Object.assign({}, Highcharts.getOptions());
      }

      applyDarkTheme() {
            Utils.loadCss(darkCssUrl, 'highChart-css');
            Utils.loadCss(darkFontUrl, 'highChart-dark');
            Highcharts.theme = darkTheme;
      }


      applyLightTheme() {
            Utils.loadCss(lightCssUrl, 'highChart-css');
            Utils.loadCss(lightFontUrl, 'highChart-light');
            Highcharts.theme = lightTheme;
      }

      applyDefaultTheme() {
            Utils.loadCss(defaultCssUrl, 'highChart-css');
            Highcharts.theme = defaultTheme;
      }



      applyTheme(theme: 'default' | 'dark' | 'light') {

            switch (theme) {
                  case 'default': this.applyDefaultTheme();
                        break;
                  case 'dark': this.applyDarkTheme();
                        break;
                  case 'light': this.applyLightTheme();
                        break;
            }
            Highcharts.setOptions(Highcharts.theme);

      }

}

