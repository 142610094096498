

export class CustomModalPopUpModel {

      id: string;
      title: string;
      button1: string;
      button2: string;
      noButtons?= false;
      large?= true;
      upperCross?= true;
      constructor() {
      }
}

