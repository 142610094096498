import { Component, OnInit, AfterViewInit } from '@angular/core';
import { IDGeneratorService } from '../../../services';
import { GraphService } from '../graph.service';
import { GraphCore } from '../graph.core';
import { SessionObject, ProjectUtils, UrlConstants } from '../../../utility';
declare var Highcharts;

@Component({
  selector: 'app-basic-line',
  templateUrl: './basic-line.component.html',
  styleUrls: ['./basic-line.component.css']
})
export class BasicLineComponent extends GraphCore implements AfterViewInit {
  NAME_CHART = 'basic-chart';
  recordData: Array<any>;
  constructor(
    protected idGeneratorService: IDGeneratorService,
    protected graphService: GraphService
  ) {
    super(graphService, idGeneratorService);
  }
  sessionObject = ProjectUtils.getSessionObject();
  aprStats = [];
  augStats= [];
  decStats= [];
  febStats= [];
  janStats= [];
  julStats= [];
  junStats= [];
  marStats= [];
  mayStats= [];
  novStats= [];
  octStats= [];
  processingMonth= [];
  processingYear= [];
  septStats= [];
  passwordStatus:any;
  
  initChart(resultdata: any) {
      
  this.passwordStatus =  this.sessionObject.userDetails.passwordStatus;
  if(this.passwordStatus==='true'){
  alert("You can change the default password to something more memorable. Please click on Top Right Side of the web page under your User name -> Profile -> Update your Account ");
  }
      
  this.recordData = resultdata['record'];
  //   resultdata.forEach(element => {
  //     this.janStats.push(element['janStats']);
  //     this.febStats.push(element['febStats']);
  //     this.marStats.push(element['marStats']);
  //     this.aprStats.push(element['aprStats']);
  //     this.mayStats.push(element['mayStats']);
  //     this.junStats.push(element['junStats']);
  //     this.julStats.push(element['julStats']);
  //     this.augStats.push(element['augStats']);
  //     this.septStats.push(element['septStats']);
  //     this.octStats.push(element['octStats']);
  //     this.novStats.push(element['novStats']);
  //     this.decStats.push(element['decStats']);
  //     this.processingYear.push(element['processingYear']);
  //     this.processingMonth.push(element['processingMonth'])
  //   });

  //   const dataArrayOne=[];
  //   const dataArrayTwo=[];
  //   const dataArrayThree=[];
  //   const dataArrayFour=[];
  //   const dataArrayFive=[];
  //   const dataArraySix=[];
  //   const FinalDataArra=[];
  //   const processingYearOne=[];
  //   const processingYearTwo=[];
  //   const processingYearThree=[];
  //   const processingYearFour=[];
  //   const processingYearFive=[];
  //   const processingYearSix=[];
  //   const processingYearArray=[];
  //  for (var index = 0; index < this.processingYear.length; index++) {
  //   if(index === 0){

  //     if(this.processingMonth[0] === 1){
  //       dataArrayOne.push(this.janStats[index]);
  //     }if(this.processingMonth[0] === 2){
  //       dataArrayOne.push(this.janStats[index],this.febStats[index]);
  //     }if(this.processingMonth[0] === 3){
  //       dataArrayOne.push(this.janStats[index],this.febStats[index],this.marStats[index]);
  //     }if(this.processingMonth[0] === 4){
  //       dataArrayOne.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index]);
  //     }if(this.processingMonth[0] === 5){
  //       dataArrayOne.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index]);
  //     }if(this.processingMonth[0] === 6){
  //       dataArrayOne.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index]);
  //     }if(this.processingMonth[0] === 7){
  //       dataArrayOne.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index],this.julStats[index]);
  //     }if(this.processingMonth[0] === 8){
  //       dataArrayOne.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index],this.julStats[index],this.augStats[index]);
  //     }if(this.processingMonth[0] === 9){
  //       dataArrayOne.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index],this.julStats[index],this.augStats[index],this.septStats[index]);
  //     }if(this.processingMonth[0] === 10){
  //       dataArrayOne.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index],this.julStats[index],this.augStats[index],this.septStats[index],this.octStats[index]);
  //     }if(this.processingMonth[0] === 11){
  //       dataArrayOne.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index],this.julStats[index],this.augStats[index],this.septStats[index],this.octStats[index],this.novStats[index]);
  //     }if(this.processingMonth[0] === 12){
  //       dataArrayOne.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index],this.julStats[index],this.augStats[index],this.septStats[index],this.octStats[index],this.novStats[index],this.decStats[index]);
  //     }
  //     processingYearOne.push(this.processingYear[0]);
  //     FinalDataArra.push(dataArrayOne);
  //     processingYearArray.push(processingYearOne);
  //   }
  //   if(index === 1){

  //     if(this.processingMonth[1] === 1){
  //       dataArrayTwo.push(this.janStats[index]);
  //     }if(this.processingMonth[1] === 2){
  //       dataArrayTwo.push(this.janStats[index],this.febStats[index]);
  //     }if(this.processingMonth[1] === 3){
  //       dataArrayTwo.push(this.janStats[index],this.febStats[index],this.marStats[index]);
  //     }if(this.processingMonth[1] === 4){
  //       dataArrayTwo.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index]);
  //     }if(this.processingMonth[1] === 5){
  //       dataArrayTwo.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index]);
  //     }if(this.processingMonth[1] === 6){
  //       dataArrayTwo.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index]);
  //     }if(this.processingMonth[1] === 7){
  //       dataArrayTwo.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index],this.julStats[index]);
  //     }if(this.processingMonth[1] === 8){
  //       dataArrayTwo.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index],this.julStats[index],this.augStats[index]);
  //     }if(this.processingMonth[1] === 9){
  //       dataArrayTwo.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index],this.julStats[index],this.augStats[index],this.septStats[index]);
  //     }if(this.processingMonth[1] === 10){
  //       dataArrayTwo.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index],this.julStats[index],this.augStats[index],this.septStats[index],this.octStats[index]);
  //     }if(this.processingMonth[1] === 11){
  //       dataArrayTwo.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index],this.julStats[index],this.augStats[index],this.septStats[index],this.octStats[index],this.novStats[index]);
  //     }if(this.processingMonth[1] === 12){
  //       dataArrayTwo.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index],this.julStats[index],this.augStats[index],this.septStats[index],this.octStats[index],this.novStats[index],this.decStats[index]);
  //     }
  //     processingYearTwo.push(this.processingYear[1]);
  //     FinalDataArra.push(dataArrayTwo);
  //     processingYearArray.push(processingYearTwo);
  //   }
  //   if(index === 2){
  //     processingYearThree.push(this.processingYear[2]);


  //     if(this.processingMonth[2] === 1){
  //       dataArrayThree.push(this.janStats[index]);
  //     }if(this.processingMonth[2] === 2){
  //       dataArrayThree.push(this.janStats[index],this.febStats[index]);
  //     }if(this.processingMonth[2] === 3){
  //       dataArrayThree.push(this.janStats[index],this.febStats[index],this.marStats[index]);
  //     }if(this.processingMonth[2] === 4){
  //       dataArrayThree.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index]);
  //     }if(this.processingMonth[2] === 5){
  //       dataArrayThree.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index]);
  //     }if(this.processingMonth[2] === 6){
  //       dataArrayThree.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index]);
  //     }if(this.processingMonth[2] === 7){
  //       dataArrayThree.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index],this.julStats[index]);
  //     }if(this.processingMonth[2] === 8){
  //       dataArrayThree.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index],this.julStats[index],this.augStats[index]);
  //     }if(this.processingMonth[2] === 9){
  //       dataArrayThree.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index],this.julStats[index],this.augStats[index],this.septStats[index]);
  //     }if(this.processingMonth[2] === 10){
  //       dataArrayThree.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index],this.julStats[index],this.augStats[index],this.septStats[index],this.octStats[index]);
  //     }if(this.processingMonth[2] === 11){
  //       dataArrayThree.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index],this.julStats[index],this.augStats[index],this.septStats[index],this.octStats[index],this.novStats[index]);
  //     }if(this.processingMonth[2] === 12){
  //       dataArrayThree.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index],this.julStats[index],this.augStats[index],this.septStats[index],this.octStats[index],this.novStats[index],this.decStats[index]);
  //     }
  //     FinalDataArra.push(dataArrayThree);
  //     processingYearArray.push(processingYearThree);
  //   }
  //   if(index === 3){
      
  //     processingYearFour.push(this.processingYear[3]);

  //     if(this.processingMonth[3] === 1){
  //       dataArrayFour.push(this.janStats[index]);
  //     }if(this.processingMonth[3] === 2){
  //       dataArrayFour.push(this.janStats[index],this.febStats[index]);
  //     }if(this.processingMonth[3] === 3){
  //       dataArrayFour.push(this.janStats[index],this.febStats[index],this.marStats[index]);
  //     }if(this.processingMonth[3] === 4){
  //       dataArrayFour.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index]);
  //     }if(this.processingMonth[3] === 5){
  //       dataArrayFour.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index]);
  //     }if(this.processingMonth[3] === 6){
  //       dataArrayFour.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index]);
  //     }if(this.processingMonth[3] === 7){
  //       dataArrayFour.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index],this.julStats[index]);
  //     }if(this.processingMonth[3] === 8){
  //       dataArrayFour.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index],this.julStats[index],this.augStats[index]);
  //     }if(this.processingMonth[3] === 9){
  //       dataArrayFour.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index],this.julStats[index],this.augStats[index],this.septStats[index]);
  //     }if(this.processingMonth[3] === 10){
  //       dataArrayFour.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index],this.julStats[index],this.augStats[index],this.septStats[index],this.octStats[index]);
  //     }if(this.processingMonth[3] === 11){
  //       dataArrayFour.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index],this.julStats[index],this.augStats[index],this.septStats[index],this.octStats[index],this.novStats[index]);
  //     }if(this.processingMonth[3] === 12){
  //       dataArrayFour.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index],this.julStats[index],this.augStats[index],this.septStats[index],this.octStats[index],this.novStats[index],this.decStats[index]);
  //     }
  //     FinalDataArra.push(dataArrayFour);
  //     processingYearArray.push(processingYearFour);

  //   }
  //   if(index === 4){
  //     processingYearFive.push(this.processingYear[4]);

    
  //     if(this.processingMonth[4] === 1){
  //       dataArrayFive.push(this.janStats[index]);
  //     }if(this.processingMonth[4] === 2){
  //       dataArrayFive.push(this.janStats[index],this.febStats[index]);
  //     }if(this.processingMonth[4] === 3){
  //       dataArrayFive.push(this.janStats[index],this.febStats[index],this.marStats[index]);
  //     }if(this.processingMonth[4] === 4){
  //       dataArrayFive.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index]);
  //     }if(this.processingMonth[4] === 5){
  //       dataArrayFive.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index]);
  //     }if(this.processingMonth[4] === 6){
  //       dataArrayFive.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index]);
  //     }if(this.processingMonth[4] === 7){
  //       dataArrayFive.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index],this.julStats[index]);
  //     }if(this.processingMonth[4] === 8){
  //       dataArrayFive.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index],this.julStats[index],this.augStats[index]);
  //     }if(this.processingMonth[4] === 9){
  //       dataArrayFive.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index],this.julStats[index],this.augStats[index],this.septStats[index]);
  //     }if(this.processingMonth[4] === 10){
  //       dataArrayFive.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index],this.julStats[index],this.augStats[index],this.septStats[index],this.octStats[index]);
  //     }if(this.processingMonth[4] === 11){
  //       dataArrayFive.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index],this.julStats[index],this.augStats[index],this.septStats[index],this.octStats[index],this.novStats[index]);
  //     }if(this.processingMonth[4] === 12){
  //       dataArrayFive.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index],this.julStats[index],this.augStats[index],this.septStats[index],this.octStats[index],this.novStats[index],this.decStats[index]);
  //     }
  //     FinalDataArra.push(dataArrayFive);
  //     processingYearArray.push(processingYearFive);
  //   }

  //   if(index === 5){
  //     processingYearSix.push(this.processingYear[5]);

    
  //     if(this.processingMonth[5] === 1){
  //       dataArraySix.push(this.janStats[index]);
  //     }if(this.processingMonth[5] === 2){
  //       dataArraySix.push(this.janStats[index],this.febStats[index]);
  //     }if(this.processingMonth[5] === 3){
  //       dataArraySix.push(this.janStats[index],this.febStats[index],this.marStats[index]);
  //     }if(this.processingMonth[5] === 4){
  //       dataArraySix.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index]);
  //     }if(this.processingMonth[5] === 5){
  //       dataArraySix.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index]);
  //     }if(this.processingMonth[5] === 6){
  //       dataArraySix.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index]);
  //     }if(this.processingMonth[5] === 7){
  //       dataArraySix.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index],this.julStats[index]);
  //     }if(this.processingMonth[5] === 8){
  //       dataArraySix.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index],this.julStats[index],this.augStats[index]);
  //     }if(this.processingMonth[5] === 9){
  //       dataArraySix.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index],this.julStats[index],this.augStats[index],this.septStats[index]);
  //     }if(this.processingMonth[5] === 10){
  //       dataArraySix.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index],this.julStats[index],this.augStats[index],this.septStats[index],this.octStats[index]);
  //     }if(this.processingMonth[5] === 11){
  //       dataArraySix.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index],this.julStats[index],this.augStats[index],this.septStats[index],this.octStats[index],this.novStats[index]);
  //     }if(this.processingMonth[5] === 12){
  //       dataArraySix.push(this.janStats[index],this.febStats[index],this.marStats[index],this.aprStats[index],this.mayStats[index],this.junStats[index],this.julStats[index],this.augStats[index],this.septStats[index],this.octStats[index],this.novStats[index],this.decStats[index]);
  //     }
  //     FinalDataArra.push(dataArraySix);
  //     processingYearArray.push(processingYearSix);
  //   }
  //  }
   
  //  alert(processingYearArray);
  //  alert(FinalDataArra);
  //   //debugger
    Highcharts.chart(this.id, {
     
        chart: {
            type: 'spline'
        },
        title: {
        	style: {
            fontFamily: "'raleway'"
        	},
            text: 'Year Trend'
        },
        subtitle: {
            text: ''
        },
      xAxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        //categories: prodName

      },
      yAxis: {
        title: {
          style: {
                fontFamily: "'raleway'"
             },
            text: 'Successful Full-text Requests'
        }
    }, tooltip: {
      crosshairs: true,
      shared: true
  },
      plotOptions: {
        spline: {
            marker: {
                radius: 4,
                lineColor: '#666666',
                lineWidth: 1
            }
        }
   },  
   series: this.recordData
    // c
//           ,
//          {
//           name: processingYearTwo,
//           marker: {
//               symbol: 'square'
//           },
//           data: dataArrayTwo
//         },
//         {
//         name: processingYearThree,
//         marker: {
//             symbol: 'square'
//         },
//         data: dataArrayThree
//         },
//         {
//       name: processingYearFour,
//       marker: {
//           symbol: 'square'
//       },
//       data: dataArrayFour
//   },
//   {
//     name: processingYearFive,
//     marker: {
//         symbol: 'square'
//     },
//     data: dataArrayFive
// },
// {
//   name: processingYearSix,
//   marker: {
//       symbol: 'square'
//   },
//   data: dataArraySix
// },
         
    //  ]
    ,
      credits: {
        enabled: false
      }
    });
    //end of chart2
  }
}
