import { Injectable } from '@angular/core';
import { HttpService } from '../../services';

import { BaseService } from '../../base';
import { Observable } from 'rxjs/Observable';

@Injectable()

export class GraphService extends BaseService {

      constructor(
            protected http: HttpService
      ) {
            super(http);
      }

      getGraphData(url: string, queryString: string) {
            return this.getDataFromAPI(url + queryString, '');
      }

}
