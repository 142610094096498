import { Observable } from 'rxjs/Observable';

export class HtmlUtil {

      public checkBox(conditionValue?: string | any, baseKey?: string, key?: string): any {

            if (conditionValue) {
                  return (val) => {
                        this.boolToCheckBox(val[baseKey][key] === conditionValue);
                        // if (val[baseKey][key] === conditionValue) {
                        //       return '<input type="checkbox" name="check" checked >';
                        // } else {
                        //       return '<input type="checkbox" name="check">';
                        // }
                  };
            } else {
                  return this.boolToCheckBox;
            }


      }

      public boolToCheckBox(val: boolean) {
            if (val) {
                  return '<input type="checkbox" name="check" checked >';
            } else {
                  return '<input type="checkbox" name="check">';
            }
      }

      scrollToCordinates(x: number, y: number) {
            const timer = Observable.timer(450, 1);
            let scrollValueY = window.scrollY || 0;
            if (y !== null) {
                  const timerSub = timer.subscribe((count) => {
                        window.scrollTo(x, scrollValueY);
                        if (y > scrollValueY) {
                              scrollValueY += 2;
                        } else {
                              timerSub.unsubscribe();
                        }

                  });

            }
      }


      public radio(conditionValue, baseKey, key, ...values): any {
            return (val) => {
                  if (val[baseKey][key] === conditionValue) {
                        return '<input type="radio" checked="true" name="' + val[baseKey][key] + '-role" value="' + values[1] + '" >';
                  } else {
                        return '<input type="radio" name="' + val[baseKey][key] + '-role" value="' + values[0] + '">';
                  }
            };
      }

      addCssToEle(targetBodyID: string, childrenEleClass: string, addClass: string, removeClass: string) {

            const topEle = document.getElementById(targetBodyID);
            const classEle: any = document.getElementsByClassName(childrenEleClass);
            const links = Array.from(classEle);
            links.forEach((link: any) => {
                  link.onclick = () => {

                        links.forEach((link2: any) => {
                              link2.classList.remove(addClass);
                              if (!link2.classList.contains(removeClass)) {
                                    link2.classList.add(removeClass);
                              }

                        });
                        link.classList.remove(removeClass);
                        link.classList.add(addClass);

                  };
            });

      }

}
