import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpService } from '../services/custom-http/';
import { ProjectUtils, SessionObject } from '../utility';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import 'rxjs/add/observable/throw';

@Injectable()

export abstract class BaseService {

stringFIndURL : string;

      getServiceUrl(name: string): string {
            return null;
      }

      constructor(
            protected http?: HttpService
      ) {
            this.xtBaseConstructor();
      }

      xtBaseConstructor() {

      }



      getDataFromAPI(strURL: string, body: any, responseType?: string): Observable<any> {
            let tokenId = null;
            tokenId = `MTIzNDV+I342MjQyNg==`;
            if (!responseType) {
                  this.getResponseType();
            }

            body = this.getBasicRequiredQueryString(body,strURL);


            if (body[0] === '&') {
                  body = '?' + body.substring(1);
            } else if (body[0] !== '?') {
                  body = '?' + body;
            }
            strURL += body;
            return this.http.extractPostData(strURL, '', tokenId, responseType)
                  .map(this.extractData)
                  .catch(this.handleError);
      }


      // uploadFile(event: any, strURL: string, nameinForm: string, otherBody?: any): Observable<any> {
      //       let tokenId = null;
      //       const sessionObj: SessionObject = ProjectUtils.getSessionObject();
      //       tokenId = sessionObj && sessionObj.userDetails.token;
      //       return this.http.uploadData(event, strURL, nameinForm, otherBody, tokenId);
      // }

      getBasicRequiredQueryString(queryString: string,strURL: string): string {
            const childdetail = ProjectUtils.getSessionObject().consortiaChildUserID;
            const userDetails = ProjectUtils.getSessionObject().userDetails;
            const onlyGraphCase = ProjectUtils.getSessionObject().conditionOnlyGraphCase;
            const changeGraphUseriD = ProjectUtils.getSessionObject().consortiaGraphLibUserId;
           
            this.stringFIndURL= null;
      if(onlyGraphCase===null){
      if(strURL.includes('consortiaAccount')){
            this.stringFIndURL = 'consortiaAdmin';
      }
      if(strURL.includes('parentAccount')){
            this.stringFIndURL = 'parentAccount';
      }
      if(childdetail === 'consortiaAdmin' && this.stringFIndURL!= 'consortiaAdmin'){
            queryString += '&user_id=' + userDetails.userID;
      }else  if(childdetail === 'consortiaAdmin' && this.stringFIndURL === 'consortiaAdmin'){
            queryString += '&user_id=' + userDetails.userID;
      }else if(childdetail != 'consortiaAdmin' && this.stringFIndURL != 'consortiaAdmin') {
          if(this.stringFIndURL == 'parentAccount'){
            queryString += '&user_id=' + userDetails.userID;
          }else{
            queryString += '&user_id=' + childdetail;
          }    
      }else if(childdetail != 'consortiaAdmin' && this.stringFIndURL === 'consortiaAdmin'){
           queryString += '&user_id=' + userDetails.userID;
      }      
      }else{
      queryString += '&user_id=' + changeGraphUseriD;
      }

                  
            return queryString;
      }


      getResponseType(): string {
            return '';
      }


      protected extractData(res: any) {

            return res;
      }

      protected handleError(error: any) {
            // In a real world app, we might use a remote logging infrastructure
            // We'd also dig deeper into the error to get a better message
            const errMsg = (error.message) ? error.message :
                  error.status ? `${error.status} - ${error.statusText}` : 'Server error';
            console.error(errMsg); // log to console instead
            return Observable.throw(errMsg);
      }
}


