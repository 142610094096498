import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { RootSharedModule } from '../sharedModules';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';

import { PieChartComponent } from './graph/pie-chart/pie-chart.component';
import { LineChartComponent } from './graph/line-chart/line-chart.component';
import { DrillDownChartComponent } from './graph/drill-down-chart/drill-down-chart.component';
import { ThreeDDonutComponent } from './graph/-3d-donut/-3d-donut.component';

import { AgGridComponent } from './ag-grid/ag-grid.component';
import { PaginationComponent } from './pagination/pagination.component';
import { BarChartComponent } from './graph/bar-chart/bar-chart.component';

import { SemiCicleDonutComponent } from './graph/semi-cicle-donut/semi-cicle-donut.component';
import { Pie3dComponent } from './graph/pie-3d/pie-3d.component';
import { TreeMapChartComponent } from './graph/tree-map/tree-map-chart.component';

import { TabComponent, TabsComponent, DynamicTabsDirective } from './ng-tabs';

import { HighChartService } from './graph';
import { DropdownComponent } from './dropdown/dropdown.component';


import { AutoCompleteComponent } from './auto-complete/auto-complete.component';
import { FilterArrayPipe } from './auto-complete/auto-complete.pipe';
import { AutoCompleteService } from './auto-complete/auto-complete.service';


import { DropdownService } from './dropdown/dropdown.service';
import { GraphService } from './graph/graph.service';

import { LoaderComponent } from './loader';
import { RingChartComponent } from './graph/ring-chart/ring-chart.component';
import { BiBarHorizontalComponent } from './graph/bi-bar-horizontal/bi-bar-horizontal.component';
import { BiBarVerticalComponent } from './graph/bi-bar-vertical/bi-bar-vertical.component';
import { CustomModalPopUpModel, CustomModalPopUpService, CustomModalPopUpComponent } from './../components/custom-modal-pop-up';
import { MonthYearComponent } from './month-year/month-year.component';
import { CustomCkeditorComponent } from './custom-ckeditor/custom-ckeditor.component';
import { FooterComponent } from './project-compo/footer/footer.component';
import { CircularLinesComponent } from './graph/circular-lines/circular-lines.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { ModalComponent } from './modal';
import { BasicLineComponent } from './graph/basic-line/basic-line.component';
import { MetricTypepie3DComponent } from './graph/metric-typepie3-d/metric-typepie3-d.component';
import { MetriclinechartComponent } from './graph/metriclinechart/metriclinechart.component';
import { YearTrendR5Component } from './graph/year-trend-r5/year-trend-r5.component';

@NgModule({
      declarations: [
            PieChartComponent,
            LineChartComponent,
            DrillDownChartComponent,
            ThreeDDonutComponent,
            AgGridComponent,
            PaginationComponent,
            BarChartComponent,
            SemiCicleDonutComponent,
            TreeMapChartComponent,
            Pie3dComponent,
            TabComponent, TabsComponent, DynamicTabsDirective,
            DropdownComponent,
            AutoCompleteComponent,
            FilterArrayPipe,
            LoaderComponent,
            RingChartComponent,
            BiBarHorizontalComponent,
            BiBarVerticalComponent,
            CustomModalPopUpComponent,
            CustomCkeditorComponent,
            MonthYearComponent,
            FooterComponent,
            CircularLinesComponent,
            ModalComponent,
            BasicLineComponent,
            MetricTypepie3DComponent,
            MetriclinechartComponent,
            YearTrendR5Component
      ],

      providers: [
            HighChartService,
            DropdownService,
            AutoCompleteService,
            GraphService,
            CustomModalPopUpService
      ],
      exports: [
            PieChartComponent,
            LineChartComponent,
            DrillDownChartComponent,
            ThreeDDonutComponent,
            AgGridComponent,
            BarChartComponent,
            SemiCicleDonutComponent,
            TreeMapChartComponent,
            Pie3dComponent,
            TabComponent, TabsComponent, DynamicTabsDirective,
            DropdownComponent,
            AutoCompleteComponent,
            LoaderComponent,
            RingChartComponent,
            BiBarHorizontalComponent,
            BiBarVerticalComponent,
            CustomModalPopUpComponent,
            CustomCkeditorComponent,
            MonthYearComponent,
            FooterComponent,
            CircularLinesComponent,
            ModalComponent,
            BasicLineComponent,
            MetricTypepie3DComponent,
            MetriclinechartComponent,
            YearTrendR5Component
      ],
      imports: [
            RootSharedModule,
            AgGridModule,
            MultiselectDropdownModule,
            CKEditorModule
      ],
      entryComponents: [TabComponent]
})

export class ComponentModule { }
