import {
  Component, OnInit, AfterViewInit
} from '@angular/core';

import { IDGeneratorService } from '../../../services';
import { GraphService } from '../graph.service';
import { GraphCore } from '../graph.core';

declare var Highcharts;
@Component({
  selector: 'app-pie-3d',
  templateUrl: './pie-3d.component.html',
  styleUrls: ['./pie-3d.component.css']
})


export class Pie3dComponent extends GraphCore implements AfterViewInit {
  NAME_CHART = 'semi-cicle-donut';

  finaldata = [];

  constructor(
    protected idGeneratorService: IDGeneratorService,
    protected graphService: GraphService
  ) {
    super(graphService, idGeneratorService);
  }

  


  initChart(resultdata: any) {
    //debugger



    resultdata.forEach(element => {
      element.name = element.key;
      element.y = parseFloat(element.value);
      delete element.key;
      delete element.value;
      // console.log(element);
    });

    Highcharts.chart(this.id, {
      chart: {
        type: 'pie',
        options3d: {
          enabled: true,
          alpha: 45,
          beta: 0
        }
      },

      title: {
        text: '<span style="font-size:15px;">Access Denial Categories</span>',
        align: 'left',
        style: {
          fontSize: '24px'
        }
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          depth: 35,
          dataLabels: {
            enabled: true,
            format: '{point.name}'
          }
        }
      },
      series: [{
        type: 'pie',
        name: 'Count',
        // data: [
        //   { name: 'Firefox', y: 45.0 },
        //   ['IE', 26.8],
        //   {
        //     name: 'Chrome',
        //     y: 12.8,
        //     sliced: true,
        //     selected: true
        //   },
        //   ['Safari', 8.5],
        //   ['Opera', 6.2],
        //   ['Others', 0.7]
        // ]
        data: resultdata
      }],
      credits: {
        enabled: false
      }
    });
  }


}
