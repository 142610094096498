import {
  Component, OnInit, AfterViewInit
} from '@angular/core';

import { IDGeneratorService } from '../../../services';
import { GraphService } from '../graph.service';
import { GraphCore } from '../graph.core';
import { ProjectUtils } from '../../../utility';

import { SessionObject, UrlConstants } from '../../../utility';

declare var Highcharts;
declare var $;

@Component({
  selector: 'app-tree-map',
  templateUrl: './tree-map-chart.component.html',
  styleUrls: ['./tree-map-chart.component.css']
})
export class TreeMapChartComponent extends GraphCore {
  queryString:String;

  NAME_CHART = 'TREE_MAP';

  constructor(
    protected idGeneratorService: IDGeneratorService,
    protected graphService: GraphService
  ) {
    super(graphService, idGeneratorService);

  }



  
  downloadTopBookTitlesPlatformChart(){
    const childdetail = ProjectUtils.getSessionObject().consortiaChildUserID;
    if(childdetail != 'consortiaAdmin'){
          this.queryString = childdetail;
    }else{
          this.queryString = ProjectUtils.getSessionObject().userDetails.userID;
    }
    
    
        const url = `${UrlConstants.DASHBOARD_GRAPH_TOP_BOOK_PLATFORMCSV}?user_id=${this.queryString}`;
        console.log('Download_URL', url);
        ProjectUtils.downloadGET(url);
    
      }


  initChart(resultdata: any) {
    var self = this;
    const data = resultdata;     //      JSON.parse(resultdata);

    Object.keys(data)
      .forEach(key => {
        if (ProjectUtils.isEmpty(data[key])) {
          delete data[key];
        }
      });

    ///  $.getJSON('https://cdn.rawgit.com/highcharts/highcharts/057b672172ccc6c08fe7dbb27fc17ebca3f5b770/samples/data/world-mortality.json',
    //   function (data) {
    console.log('tree-map>,data', data);

    var points = [],
      regionP,
      regionVal,
      regionI = 0,
      countryP,
      countryI,
      causeP,
      causeI,
      region,
      country,
      cause,
      causeName = {
        'YTD': 'YTD'
      };

    for (region in data) {


      if (data.hasOwnProperty(region)) {
        regionVal = 0;
        regionP = {
          id: 'id_' + regionI,
          name: region,
          color: Highcharts.getOptions().colors[regionI]
        };
        countryI = 0;
        for (country in data[region]) {
          if (data[region].hasOwnProperty(country)) {
            countryP = {
              id: regionP.id + '_' + countryI,
              name: country,
              parent: regionP.id
            };
            points.push(countryP);
            causeI = 0;
            for (cause in data[region][country]) {
              if (data[region][country].hasOwnProperty(cause)) {
                causeP = {
                  id: countryP.id + '_' + causeI,
                  name: causeName[cause],
                  parent: countryP.id,
                  value: Math.round(+data[region][country][cause])
                };
                regionVal += causeP.value;
                points.push(causeP);
                causeI = causeI + 1;
              }
            }
            countryI = countryI + 1;
          }
        }
        regionP.value = Math.round(regionVal / countryI);
        points.push(regionP);
        regionI = regionI + 1;
      }

    }
    console.log('points', points);

    console.log('Tree Map:thisid', self.id);
    Highcharts.chart(self.id, {
      series: [{
        type: 'treemap',
        layoutAlgorithm: 'squarified',
        allowDrillToNode: true,
        animationLimit: 1000,
        dataLabels: {
          enabled: false
        },
        levelIsConstant: false,
        levels: [{
          level: 1,
          dataLabels: {
            enabled: true
          },
          borderWidth: 3
        }],

        data: points
      }],
      subtitle: {
        text: 'Successful Title Requests',
        align: 'left',
      },

      title: {
        text: '<span style="font-size:15px;">Top Book Titles by Collection Platforms</span>',
        align: 'left',
        style: {
          fontSize: '24px'
        }
      },
      credits: {
        enabled: false
      }


    });

    // }
    //  );
  }



}
