import { Component, VERSION, Version } from '@angular/core';
import { Router } from '@angular/router';
import { Utils } from './acore/utility';

declare var Highcharts;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(
    private router: Router
  ) {
    // console.log(this.router.config);
    this.addCKEditor();
    this.renderDownload();
  }


  addCKEditor() {
    const url = './assets/js/ckeditor/ckeditor.js';
    Utils.loadJS(url);

  }

  renderDownload() {
    Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
      var path = [



        // Arrow stem
        'M', x + w * 0.8, y,
        'L', x + w * 0.8, y,

        // Arrow head
        'M', x + w * 0.4, y,
        'L', x + w * 0.4, y,
        // Box
        'M', x, y,
        'L', x, y,
        'L', x, y,
        'L', x, y
      ];
      return path;
    };
  }

}
