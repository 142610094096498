import { SessionObject } from './session-object';
import { Utils } from '../util';
declare var $;
export class ProjectUtils extends Utils {

      public static GRIDWIDTH = window.innerWidth - ((1 * ProjectUtils.getScrollbarWidth()) + 30);


      public static getScrollbarWidth() {
            const outer = document.createElement('div');
            outer.style.visibility = 'hidden';
            outer.style.width = '100px';
            outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps

            document.body.appendChild(outer);

            const widthNoScroll = outer.offsetWidth;
            // force scrollbars
            outer.style.overflow = 'scroll';

            // add innerdiv
            const inner = document.createElement('div');
            inner.style.width = '100%';
            outer.appendChild(inner);

            const widthWithScroll = inner.offsetWidth;

            // remove divs
            outer.parentNode.removeChild(outer);

            return widthNoScroll - widthWithScroll;
      }










      public static setSessionObject(sessionObject: SessionObject) {
            sessionStorage.setItem('sessionObject', JSON.stringify(sessionObject));
            //localStorage.setItem('sessionObject', JSON.stringify(sessionObject));
      }

      public static getSessionObject(): SessionObject {
            const sessionObject = <SessionObject>JSON.parse(sessionStorage.getItem('sessionObject'));
            //const sessionObject = <SessionObject>JSON.parse(localStorage.getItem('sessionObject'));
            return sessionObject;
      }

      public static clearSessionObjects() {
            sessionStorage.removeItem('sessionObject');
            //localStorage.removeItem('sessionObject');
      }

      public static setDashBoardPost(dashBoardSearchModel: any) {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            sessionObject.dashBoardPost = dashBoardSearchModel;
            ProjectUtils.setSessionObject(sessionObject);
      }

      public static getDashBoardPost() {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            return sessionObject.dashBoardPost;
      }

      public static setDashBoardSec(dashBoardSecSearchModel: any) {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            sessionObject.dashBoardSec = dashBoardSecSearchModel;
            ProjectUtils.setSessionObject(sessionObject);
      }

      public static getDashBoardSec() {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            return sessionObject.dashBoardSec;
      }

      public static setCounterReports(counterReportsSearchModel: any) {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            sessionObject.counterReports = counterReportsSearchModel;
            ProjectUtils.setSessionObject(sessionObject);
      }

      public static getCostReports() {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            return sessionObject.costReports;
      }

      public static setCostReports(costReportsSearchModel: any) {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            sessionObject.costReports = costReportsSearchModel;
            ProjectUtils.setSessionObject(sessionObject);
      }

      public static getCounterReports() {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            return sessionObject.counterReports;
      }

      public static setDynamicReports(dynamicReportsSearchModel: any) {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            sessionObject.dynamicReports = dynamicReportsSearchModel;
            ProjectUtils.setSessionObject(sessionObject);
      }

      public static getDynamicReports() {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            return sessionObject.dynamicReports;
      }

      public static setQueryToolReports(queryToolModel: any) {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            sessionObject.queryToolReports = queryToolModel;
            ProjectUtils.setSessionObject(sessionObject);
      }

      public static getQueryToolReports() {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            return sessionObject.queryToolReports;
      }


      

      public static setCounter5Reports(counter5Model: any) {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            sessionObject.counter5Reports = counter5Model;
            ProjectUtils.setSessionObject(sessionObject);
      }

      public static getCounter5Reports() {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            return sessionObject.counter5Reports;
      }


      public static setCustomReports(customReportsSearchModel: any) {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            sessionObject.customReports = customReportsSearchModel;
            ProjectUtils.setSessionObject(sessionObject);
      }

      public static getCustomReports() {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            return sessionObject.sourceReports;
      }

      public static setSourceReports(sourceReportsSearchModel: any) {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            sessionObject.sourceReports = sourceReportsSearchModel;
            ProjectUtils.setSessionObject(sessionObject);
      }

      public static setIssnIsbnReports(isnIsbnReportsSearchModel: any) {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            sessionObject.issnIsbnReports = isnIsbnReportsSearchModel;
            ProjectUtils.setSessionObject(sessionObject);
      }

      public static getSourceReports() {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            return sessionObject.sourceReports;
      }

      public static getIssnIsbnReports() {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            return sessionObject.issnIsbnReports;
      }
      public static getIssnIsbnReportsC5() {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            return sessionObject.issnIsbnReportsC5;
      }
     

      public static setDashBoardCharts(sourceDashBoardCharts: any) {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            sessionObject.dashBoardCharts = sourceDashBoardCharts;
            ProjectUtils.setSessionObject(sessionObject);
      }

      public static getDashBoardCharts() {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            return sessionObject.dashBoardCharts;
      }

      public static setZeroUsageJournal(zeroUsageSearchModel: any) {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            sessionObject.zeroUsageJournal = zeroUsageSearchModel;
            ProjectUtils.setSessionObject(sessionObject);
      }

      public static getZeroUsageJournal() {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            return sessionObject.zeroUsageJournal;
      }

      public static setGraphData(graphData) {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            sessionObject.graphData = graphData;
            ProjectUtils.setSessionObject(sessionObject);
      }

      public static getGraphData() {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            return sessionObject && sessionObject.graphData;
      }
      public static setThemeData(themeData) {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            sessionObject.themeData = themeData;
            ProjectUtils.setSessionObject(sessionObject);
      }

      public static getThemeData() {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            return sessionObject && sessionObject.themeData;
      }



      public static svgCsv(value: any) {
            if ((value.value).includes('csv')) {
                  return '<img src="./assets/images/csv.svg" class="curPointer" />';
            }
            return '<img src="./assets/images/csv_gray.svg" class="curDisabled" />';
      }

      public static svgExcel(value: any) {
            if ((value.value).includes('xls')) {
                  return '<img src="./assets/images/excel.svg" class="curPointer" />';
            }
            return '<img src="./assets/images/excel_gray.svg" class="curDisabled" />';
      }
      public static svgExcelx(value: any) {
            if ((value.value).includes('xlsx')) {
                  return '<img src="./assets/images/excel.svg" class="curPointer" />';
            }
            return '<img src="./assets/images/excel_gray.svg" class="curDisabled" />';
      }
      public static svgZip(value: any) {
            if ((value.value).includes('zip')) {
                  return '<img src="./assets/images/zip.svg" class="curPointer" />';
            }
            return '<img src="./assets/images/zip_gray.svg" class="curDisabled" />';
      }
      public static svgText(value: any) {
            if ((value.value).includes('txt')) {
                  return '<i class="fa fa-file-text curPointer" style="font-size:20px"></i>';
            }
            return '<i class="fa fa-file-text curDisabled" style="font-size:20px" aria-hidden="true"></i>';
      }
      public static svgHtml(value: any) {
            if ((value.value).includes('html')) {
                  return '<i class="fa fa-html5 curPointer" style="font-size:20px"></i>';
            }
            return '<i class="fa fa-html5 curDisabled" style="font-size:20px" aria-hidden="true"></i>';
      }
      public static svgXml(value: any) {
            if ((value.value).includes('xml')) {
                  return '<i class="fa fa-file-code-o curPointer" style="font-size:20px"></i>';
            }
            return '<i class="fa fa-file-archive-o curDisabled" style="font-size:20px" aria-hidden="true"></i>';
      }
      // 'csv,xls,xml,html,txt'
      public static ag_SetWidth(perc: number) {

            // let retValue = (ProjectUtils.GRIDWIDTH * perc) / 100;
            // retValue = parseInt(retValue + '', 10);
            // return retValue;
            return perc;
      }

      public static limitStringNAddToolTip(val: any) {
            const noOfChars = 45;
            val = val.value;
            const str = val.substring(0, noOfChars);
            let toShow = null;
            if (val.length > noOfChars) {
                  toShow = str + '...';
            } else {
                  toShow = str;
            }
            return `<span title=">${val}">${toShow}</span>`;
      }


      public static initToolTip() {
            const options = {
                animation: true,
                delay: 100,
                placement: 'auto',
                trigger: 'hover focus'
            }
            console.log($('.tooltipInfo').tooltip(options))
        }
    

      public static inputField(value: number) {
            return '<input type="text" style="font-size: small;line-height: normal;" >';
      }

      public static editDiv(value: any) {
            return '<a href="javascript:;" style="font-size:16px;"><i class="fa fa-edit"></i></a>';
      }

      public static isPermissionGranted(value: any) {
            if (value.colDef['headerName'] === 'Permissions' && (value.value) === 'true') {
                  return '<div class="square-block-selected"></div>';
            } else {
                  return '<div class="square-block-not-available"></div>';
            }
      }

      public static ermAccess(value: any) {

            if (value.colDef['headerName'] === 'Access details for your ERM system' && (value.value) === 'true') {
                  return '<img src="./assets/images/accessDetails.svg" class="curPointer accessDetails" />';
            } else if (value.colDef['headerName'] === 'Access details for your ERM system' && (value.value) === 'false') {
                  return '<img src="./assets/images/accessDetails-reject.svg" class="curDisabled accessDetails" />';
            }
      }

      public static numberValueParser(params) {
            return Number(params.newValue);
      }

      public static ag_selectedDiv(value: any) {
            if (value.colDef['headerName'] === 'Selected Platforms' && value.value !== '') {
                  return '<div class="square-block-selected"></div>';
            }
            return '';
      }

      public static ag_ColorDiv(header: any) {

            const temp = '<div class="square-block-selected"></div>';
            if (header.colDef['headerName'] === 'JR1' && (header.value).includes('JR1')) {
                  return temp;
            } else if (header.colDef['headerName'] === 'JR1a' && (header.value).includes('JR1a')) {
                  return temp;
            } else if (header.colDef['headerName'] === 'JR2' && (header.value).includes('JR2')) {
                  return temp;
            } else if (header.colDef['headerName'] === 'JR3' && (header.value).includes('JR3')) {
                  return temp;
            } else if (header.colDef['headerName'] === 'JR5' && (header.value).includes('JR5')) {
                  return temp;
            } else if (header.colDef['headerName'] === 'DB1' && (header.value).includes('DB1')) {
                  return temp;
            } else if (header.colDef['headerName'] === 'DB2' && (header.value).includes('DB2')) {
                  return temp;
            } else if (header.colDef['headerName'] === 'PR1' && (header.value).includes('PR1')) {
                  return temp;
            } else if (header.colDef['headerName'] === 'BR1' && (header.value).includes('BR1')) {
                  return temp;
            } else if (header.colDef['headerName'] === 'BR2' && (header.value).includes('BR2')) {
                  return temp;
            } else if (header.colDef['headerName'] === 'DR' && (header.value).includes('DR')) {
                  return temp;
            } else if (header.colDef['headerName'] === 'DR_D1' && (header.value).includes('DR_D1')) {
                  return temp;
            } else if (header.colDef['headerName'] === 'DR_D2' && (header.value).includes('DR_D2')) {
                  return temp;
            } else if (header.colDef['headerName'] === 'IR' && (header.value).includes('IR')) {
                  return temp;
            } else if (header.colDef['headerName'] === 'IR_A1' && (header.value).includes('IR_A1')) {
                  return temp;
            } else if (header.colDef['headerName'] === 'IR_M1' && (header.value).includes('IR_M1')) {
                  return temp;
            } else if (header.colDef['headerName'] === 'PR' && (header.value).includes('PR')) {
                  return temp;
            } else if (header.colDef['headerName'] === 'PR_P1' && (header.value).includes('PR_P1')) {
                  return temp;
            } else if (header.colDef['headerName'] === 'TR' && (header.value).includes('TR')) {
                  return temp;
            } else if (header.colDef['headerName'] === 'TR_B1' && (header.value).includes('TR_B1')) {
                  return temp;
            } else if (header.colDef['headerName'] === 'TR_B2' && (header.value).includes('TR_B2')) {
                  return temp;
            } else if (header.colDef['headerName'] === 'TR_B3' && (header.value).includes('TR_B3')) {
                  return temp;
            } else if (header.colDef['headerName'] === 'TR_J1' && (header.value).includes('TR_J1')) {
                  return temp;
            } else if (header.colDef['headerName'] === 'TR_J2' && (header.value).includes('TR_J2')) {
                  return temp;
            } else if (header.colDef['headerName'] === 'TR_J3' && (header.value).includes('TR_J3')) {
                  return temp;
            } else if (header.colDef['headerName'] === 'TR_J4' && (header.value).includes('TR_J4')) {
                  return temp;
            } else {
                  return '<div class="square-block-not-available"></div>';
            }
      }

      public static filterDataFromDataKey(data: any) {
            if (data['data']) {
                  return data['data'];
            } else {
                  return data;
            }
      }

      public static getIdAsArray(obj: any): any[] {
            const objectIds = [];
            if (!Utils.isEmpty(obj)) {
                  if (Array.isArray(obj)) {
                        for (const value of obj) {
                              console.log(value);
                              objectIds.push(value.id);
                        }
                  } else {
                        objectIds.push(obj.id);
                  }
            }
            console.log('objectIds', objectIds);
            return objectIds;
      }

}
