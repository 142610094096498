import { UserDetails } from '../../../pages/login/core-login/user-details.model';

export class SessionObject {
      /* **************  USER DETAILS  *************** */
      userDetails: UserDetails;
      liveYear: any;
      liveMonth: any;
      themeData: any;
      /* **************  USER DETAILS  *************** */

      /* **************  DASHBOARD  *************** */
      dashBoardPost: any;
      dashBoardSec: any;


      dashBoardCharts: any;

      /* **************  DASHBOARD  *************** */




      /* **************  REPORTS  *************** */

      counterReports: any;
      customReports: any;
      costReports: any;
      sourceReports: any;
      dynamicReports: any;
      issnIsbnReports: any;
      issnIsbnReportsC5: any;
      counter5Reports: any;
      queryFilterTab: any;
      jsonFilterTab: any;
      /* **************  REPORTS  *************** */



      /* **************  ADMINISTRATIVE FEATURES  *************** */

      /* **************  ADMINISTRATIVE FEATURES  *************** */



      /* **************  DATA ANALYSIS TOOL  *************** */
      zeroUsageJournal: any;
      increaseInUsageJournal: any;
      queryToolReports:any;
      childConsortia : any;
      /* **************  DATA ANALYSIS TOOL  *************** */


      graphData: any;
      consortiaChildUserID: any;
consortiaGraphLibUserId : any;
conditionOnlyGraphCase : any;

      constructor() {

            this.graphData = null;

           this.consortiaChildUserID = 'consortiaAdmin';

this.consortiaGraphLibUserId= null;
this.conditionOnlyGraphCase = null;

            /* **************  USER DETAILS  *************** */
            this.userDetails = null;
            this.liveYear = null;
            this.liveMonth = null;
            /* **************  USER DETAILS  *************** */

            /* **************  DASHBOARD  *************** */
            this.dashBoardPost = null;
            this.dashBoardSec = null;
            this.dashBoardCharts = null;
            /* **************  DASHBOARD  *************** */




            /* **************  REPORTS  *************** */
            this.counterReports = null;
            this.counter5Reports = null;
            this.costReports = null;
            this.customReports = null;
            this.dynamicReports = null;
            this.sourceReports = null;
            this.issnIsbnReports = null;
            this.issnIsbnReportsC5 = null;
            /* **************  REPORTS  *************** */



            /* **************  ADMINISTRATIVE FEATURES  *************** */

            /* **************  ADMINISTRATIVE FEATURES  *************** */



            /* **************  DATA ANALYSIS TOOL  *************** */
            this.zeroUsageJournal = null;
            this.increaseInUsageJournal = null;
            this.childConsortia = null;
            /* **************  DATA ANALYSIS TOOL  *************** */










      }
}
