export class GlobalLoaderUitl {

      assignGlobalLoaderTask(hideTsk: any, showTsk: any) {
            this.hideGlobalLoader = hideTsk;
            this.showGlobalLoader = showTsk;
      }

      showGlobalLoader() {
      }

      hideGlobalLoader() {

      }

}