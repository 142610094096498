import { OnChanges, Input } from '@angular/core';
import { BaseComponent } from './base.component';
import { BaseService } from './base.service';
import { ModalApi } from '../components/modal';




export class ModalBase extends BaseComponent implements OnChanges {

      @Input() modalApi: ModalApi;


      constructor(
            protected baseService: BaseService,
      ) {
            super(baseService);
      }

      ngOnChanges() {

            if (this.modalApi) {
                  this.modalApi.onShow = this.reInit;
            }

      }

      reInit = (param: any) => {
      }

}
