import {
  Component, OnInit, AfterViewInit, Input, OnChanges
} from '@angular/core';

import { IDGeneratorService } from '../../../services';
import { GraphService } from '../graph.service';
import { GraphCore } from '../graph.core';

import { SessionObject, ProjectUtils, UrlConstants } from '../../../utility';

declare var Highcharts;
@Component({
  selector: 'app--3d-donut',
  templateUrl: './-3d-donut.component.html',
  styleUrls: ['./-3d-donut.component.css']
})
export class ThreeDDonutComponent extends GraphCore implements AfterViewInit {

  queryString:String;
  NAME_CHART = 'semi-cicle-donut';

  constructor(
    protected idGeneratorService: IDGeneratorService,
    protected graphService: GraphService
  ) {
    super(graphService, idGeneratorService);


  }


  downloadHighestPlatformChart(){
    const childdetail = ProjectUtils.getSessionObject().consortiaChildUserID;
    if(childdetail != 'consortiaAdmin'){
          this.queryString = childdetail;
    }else{
          this.queryString = ProjectUtils.getSessionObject().userDetails.userID;
    }
    
    
        const url = `${UrlConstants.DASHBOARD_GRAPH_HIGHEST_ZERO_USAGECSV}?user_id=${this.queryString}`;
        console.log('Download_URL', url);
        ProjectUtils.downloadGET(url);
    
      }



  initChart(resultdata: any) {

    const chartData = [];

    resultdata.forEach(element => {
      chartData.push([element['mpsCollectionPlatform'], element['count']]);

    });


    Highcharts.chart(this.id, {
      chart: {
        type: 'pie',
        options3d: {
          enabled: true,
          alpha: 45
        }
      },

      title: {
        text: '<span style="font-size:15px;">Highest Zero Usages Platform</span>',
        align: 'left',
        style: {
          fontSize: '24px'
        }
      },
      subtitle: {
        text: 'Successful Full-Text Requests',
        align: 'left',
      },
      plotOptions: {
        pie: {
          innerSize: 100,
          depth: 45
        }
      },
      series: [{
        name: 'Count',
        // data: [
        //   ['Bananas', 8],
        //   ['Kiwi', 3],
        //   ['Mixed nuts', 1],
        //   ['Oranges', 6],
        //   ['Apples', 8],
        //   ['Pears', 4],
        //   ['Clementines', 4],
        //   ['Reddish (bag)', 1],
        //   ['Grapes (bunch)', 1]
        // ]
        data: chartData
      }],
      credits: {
        enabled: false
      },
    });


  }
}
