import { Component, OnInit } from '@angular/core';
import { IDGeneratorService } from '../../../services';
import { GraphService } from '../graph.service';
import { GraphCore } from '../graph.core';
import { SessionObject, ProjectUtils, UrlConstants } from '../../../utility';
declare var Highcharts;

@Component({
  selector: 'app-bi-bar-horizontal',
  templateUrl: './bi-bar-horizontal.component.html',
  styleUrls: ['./bi-bar-horizontal.component.css']
})
export class BiBarHorizontalComponent extends GraphCore {
  NAME_CHART = 'bar-chart';
  queryString :  String;
  constructor(
    protected idGeneratorService: IDGeneratorService,
    protected graphService: GraphService
  ) {
    super(graphService, idGeneratorService);

  }

  downloadJornalStatRestChart(){
    const childdetail = ProjectUtils.getSessionObject().consortiaChildUserID;
    if(childdetail != 'consortiaAdmin'){
          this.queryString = childdetail;
    }else{
          this.queryString = ProjectUtils.getSessionObject().userDetails.userID;
    }
    
    
    const monthLive= ProjectUtils.getSessionObject().liveMonth;
        const url = `${UrlConstants.DASHBOARD_GRAPH_TOP_JOURNALS_CSV}?user_id=${this.queryString}&month=${monthLive}`;
        console.log('Download_URL', url);
        ProjectUtils.downloadGET(url);
    
      }
 
      
  JournalStats= [];
  ytdStats=[];
  janStats=[];
  

  initChart(resultdata: any) {

    resultdata.forEach(element => {

      this.JournalStats.push(element['journalTitle']);
      this.janStats.push(element['janStats']);
      this.ytdStats.push(element['ytd']);
    });

    Highcharts.chart(this.id, {
      chart: {
        type: 'column'
      },
      title: {
        text: '<span style="font-size:15px;">Top Journal Stats comparision with rest of the titles</span>',
        align: 'left',
        style: {
          fontSize: '24px'
        }
      },
      subtitle: {
        text: '  '
      },
      xAxis: {
        categories: [
          this.JournalStats[0],
          this.JournalStats[1],
          this.JournalStats[2],
          this.JournalStats[3],
          this.JournalStats[4],
          this.JournalStats[5],
          this.JournalStats[6],
          this.JournalStats[7],
          this.JournalStats[8],
          this.JournalStats[9],



        ],
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Values'
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.1,
          borderWidth: 0
        }
      },
      series: [{
        name: 'Top Journal YTD Stats',
        data: [   this.janStats[0],
        this.janStats[1],
        this.janStats[2],
        this.janStats[3],
        this.janStats[4],
        this.janStats[5],
        this.janStats[6],
        this.janStats[7],
        this.janStats[8],
        this.janStats[9]],
        color: '#aacce7'

      }, {
        name: 'YTD Stats-Excluding top Journal',
        data: [this.ytdStats[0],
        this.ytdStats[1],
        this.ytdStats[2],
        this.ytdStats[3],
        this.ytdStats[4],
        this.ytdStats[5],
        this.ytdStats[6],
        this.ytdStats[7],
        this.ytdStats[8],
        this.ytdStats[9]],
        color: '#8abe6e'

      }],
      credits: {
        enabled: false
      }
    });
  }
}
