import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { SessionObject, ProjectUtils } from '../../utility';

@Injectable()
export class LoginGuard implements CanActivate {

      constructor(
            private router: Router
      ) { }

      authUser(): Promise<boolean> {
            const sessionOj: SessionObject = ProjectUtils.getSessionObject();
            return new Promise((resolve, reject) => {

                  if (ProjectUtils.isEmpty(sessionOj && sessionOj.userDetails && sessionOj.userDetails.userID)) {
                        resolve(true);
                  } else {
                        resolve(false);
                        this.router.navigate(['/pages']);

                  }
            });
      }

      canActivate(): Promise<boolean> {
            return this.authUser();
      }



}
