

export class UrlConstants {

  // static SCHOLARLYSTATS_BASE_URL = 'http://10.66.11.76:8087/scholarlystats_alpha/services/';
  //static SCHOLARLYSTATS_BASE_URL =    'https://beta-scholarlystats.mpsinsight.com/scholarlystats_alpha/services/';
   static SCHOLARLYSTATS_BASE_URL = 'https://c5.mpsscholarlystats.com/scholarlystats_alpha/services/';  
   static THIRD = UrlConstants.SCHOLARLYSTATS_BASE_URL + `platformReport/getPlatformSpecificReports`;
   
          /* **************  USER DETAILS  *************** */
      static LOGIN = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'userservice/login';
      static NEWPASSWORD = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'userservice/getnewpassword';
      static FORGETPASSWORD = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'userservice/getsecquestion';
      static REPORTING_PERIOD = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'platformReport/reportingPeriod';
      static PLATFORM_DETAILS = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'platformReport/getAllPlatformDetails';
     
      static C5PLATFORM_DETAILS = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'platformReport/getAllPlatformDetailsC5';
      static LIVE_MONTH = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'platformReport/getlivemonth';
      static LIVE_YEAR = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'platformReport/getliveyear';
      static C5_REPORTING_PERIOD = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'platformReport/c5ReportingPeriod';
      /* **************  USER DETAILS  *************** */

      
      

      /* **************  DASHBOARD  *************** */
      static DASHBOARD_GRAPH_YEAR_TOP_JOURNAL = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'summary/yearTopJournalsGraph';
      static DASHBOARD_GRAPH_YEAR_TOP_JOURNALCSV = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'summary/yearTopJournalsGraphcsv';
      static DASHBOARD_GRAPH_USAGE_DIVISION = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'summary/usagedivision';
      static DASHBOARD_GRAPH_TRENDYEAR = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'summary/trendyear';
      static DASHBOARD_GRAPH_TRENDYEAR_R5 = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'summary/trendyearr5';
      static DASHBOARD_GRAPH_TOP_JOURNALS = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'summary/toptenjournals';
      static DASHBOARD_GRAPH_TOP_JOURNALS_CSV = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'summary/toptenjournalscsv';
      static DASHBOARD_GRAPH_ZERO_USAGE_JOURNALS = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'summary/zerousagejournals';
      static DASHBOARD_GRAPH_JOURNAL_TOP_ACCESS_DENAIL = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'summary/journalaccessdenial';
      static DASHBOARD_GRAPH_JOURNAL_TOP_ACCESS_DENAILCSV = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'summary/journalaccessdenialcsv';
      static DASHBOARD_GRAPH_DENIED_CATEGORY_PERC = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'summary/denialcategory';
      static DASHBOARD_GRAPH_METRIC_WISE_SUM = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'summary/metricWiseTotalSum';
      static DASHBOARD_GRAPH_MONTH_WISE_METRIC_SUM = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'summary/monthWiseMetricSum';
      static DASHBOARD_GRAPH_MONTH_WISE_METRIC_SUM_CSV = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'summary/monthWiseMetricSumCSV';
      static DASHBOARD_GRAPH_PLATFORM_CONTRIBUTION = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'summary/platformcontribution';
      static DASHBOARD_GRAPH_PLATFORM_PLATFORM_AVERAGE_FULLTEXT_VIEW = UrlConstants.SCHOLARLYSTATS_BASE_URL +
            'summary/platformaveragefulltextviews';
            static DASHBOARD_GRAPH_PLATFORM_PLATFORM_AVERAGE_FULLTEXT_VIEWCSV = UrlConstants.SCHOLARLYSTATS_BASE_URL +
            'summary/platformaveragefulltextviewscsv';
      static DASHBOARD_GRAPH_HIGHEST_ZERO_USAGE = UrlConstants.SCHOLARLYSTATS_BASE_URL +
            'summary/highestzerousage';
            static DASHBOARD_GRAPH_HIGHEST_ZERO_USAGECSV = UrlConstants.SCHOLARLYSTATS_BASE_URL +
            'summary/highestzerousagecsv';
      static DASHBOARD_GRAPH_HIGHEST_LOW_USAGESTATS = UrlConstants.SCHOLARLYSTATS_BASE_URL +
            'summary/lowusagestats';
            static DASHBOARD_GRAPH_HIGHEST_LOW_USAGESTATSCSV = UrlConstants.SCHOLARLYSTATS_BASE_URL +
            'summary/lowusagestatscsv';
      
      static DASHBOARD_GRAPH_TOP_BOOK_PLATFORM = UrlConstants.SCHOLARLYSTATS_BASE_URL +
            'summary/topbookplatform';

            
      static DASHBOARD_GRAPH_TOP_BOOK_PLATFORMCSV = UrlConstants.SCHOLARLYSTATS_BASE_URL +
      'summary/topbookplatformcsv';
      static LOGGED_IN_USER = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'manageuser/useraccountdetails';
      static UPDATE_PROFILE = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'manageuser/updateUserDetails';
      static CONTACT_QUERY_LIST = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'contactus/gettypeofquery';
      static SEND_QUERY_MAIL = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'contactus/sendquerymail';
      static MAKE_DIRECT_PAYMENT = UrlConstants.SCHOLARLYSTATS_BASE_URL + '';
      static GETUSERGRAPH = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'summary/getGraph';
      static UPDATEUSERGRAPH = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'summary/updateGraph';
      static GETIP = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'userservice/ip';
      static GETTABDETAIL = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'userservice/getTabDetail';
      static GETCONSORTIAACCOUNT= UrlConstants.SCHOLARLYSTATS_BASE_URL + 'userservice/consortiaAccount';
      static GETPARENTACCOUNT= UrlConstants.SCHOLARLYSTATS_BASE_URL + 'userservice/parentAccount';
      static GETALLCONSORTIAACCOUNT= UrlConstants.SCHOLARLYSTATS_BASE_URL + 'userservice/consortiaAllAccount';
      static GETDASHBOARDCONSORTIAACCOUNT= UrlConstants.SCHOLARLYSTATS_BASE_URL + 'userservice/consortiaDashboard';
      
      /* **************  DASHBOARD  *************** */


      /* **************  REPORTS  *************** */
      // static REPORTS = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'accounts/generatedreports?user_id=1864470&year=2018&month=01&screen=LIVE';
      static REPORTS = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'accounts/generatedreports';
      static SOURCEREPORTS = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'platformReport/getPlatformSpecificReports';
      static C5SOURCEREPORTS = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'platformReport/getC5PlatformSpecificReports';
      static REPORTS_COUNTER_REPORTING_PERIOD = UrlConstants.SCHOLARLYSTATS_BASE_URL +
            'platformReport/reportingPeriod?platform_id=19';
      static REPORTS_COUNTER_GET_AVAILABLE_REPORTS = UrlConstants.SCHOLARLYSTATS_BASE_URL +
            'platformReport/getPlatformSpecificReports?platform_id=19&year=2013&month=12';
      static REPORTS_GET_CHART = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'report/getchart';
      static SEARCH_ISSN_CSV= UrlConstants.SCHOLARLYSTATS_BASE_URL +
      'report/searchissn'
      // GET
      static REPORTS_DOWNLOAD_REPORTS = UrlConstants.SCHOLARLYSTATS_BASE_URL +
            'report/download';
      static COUNTER5_DOWNLOAD_REPORTS = UrlConstants.SCHOLARLYSTATS_BASE_URL +
            'report/counter5reportdownload';
      static DOWNLOAD_SOURCE_REPORT = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'report/download/platform';
      static DYNAMIC_REPORTS_TYPE = UrlConstants.SCHOLARLYSTATS_BASE_URL +
            'dynamicReport/getReportType';
      static COUNTER5_REPORTS_TYPE = UrlConstants.SCHOLARLYSTATS_BASE_URL +
            'counter5/reportlist';
      static GET_DYNAMIC_REPORTS_DATA = UrlConstants.SCHOLARLYSTATS_BASE_URL +
            'dynamicReport/getreport';
      static COUNTER5_REPORTS = UrlConstants.SCHOLARLYSTATS_BASE_URL +
            'counter5/counter5reportjson';
      static SEND_EMAIL = UrlConstants.SCHOLARLYSTATS_BASE_URL +
            '';
      static GETDEFAULTREPORTINGPERIOD = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'platformReport/defaultReportingPeriod';
      static GETISSNISBNDETAIL = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'bookjournal/getBookJournalDetails';
      static GETISSNISBNDETAILC5 = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'bookjournal/getTitleDetailsC5';
      static GET_ISSN_YOP = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'bookjournal/getISSNYOPValue';
     static GET_ISSN_METRICTYPE =UrlConstants.SCHOLARLYSTATS_BASE_URL + 'bookjournal/getISSNMetricValue';

      static GETC5DEFAULTREPORTINGPERIOD = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'platformReport/c5defaultReportingPeriod';
      static GETSEARCHTYPE = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'search/getsearchtype';
      static SETTING_OF_ISSN_ISBN = UrlConstants.SCHOLARLYSTATS_BASE_URL +
            'bookjournal/fetchIssnIsbn';
      static C5_DYNAMIC_REPORTS_TYPE = UrlConstants.SCHOLARLYSTATS_BASE_URL +
            'dynamicReport/getC5ReportType';
      static C5_GET_DYNAMIC_REPORTS_DATA = UrlConstants.SCHOLARLYSTATS_BASE_URL +
            'dynamicReport/getreportC5';
      static C5_DYNAMIC_REPORTS_ATTRIBUTES = UrlConstants.SCHOLARLYSTATS_BASE_URL +
            'dynamicReport/getC5YOP';
      //   '/dynamicReport/getreport?user_id=1864470&from=01-2016&to=03-2016&report=jr1'
      /* **************  REPORTS  *************** */

      /* **************  ADMINISTRATIVE FEATURES  *************** */
      static SELECTED_PLATFORM_DETAILS = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'platformReport/getAllPlatformWithReportId';
      static GET_PLATFORM_DETAILS = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'platformReport/getAllPlatformAccessDetails';
      static EDIT_ACCESS_DETAILS = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'platformReport/getPlatformDetail/';
      static UPDATE_PLATFORM_ACCESS_DETAILS = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'platformReport/updatePlatformAccessDetails/';
      static GET_LIBRARY_DETAILS = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'manageuser/getLibraryDetails';
      static AVAILABLE_SUSHI_PARTNER = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'manageuser/getAvailableSushiPartners';
      static GET_SUSHI_PARTNER = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'manageuser/getSushiPartnerDetails';
      static UPDATE_SUSHI_PARTNER = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'manageuser/insertsushipartnermapping';
      static ADDITIONAL_USER_ACCOUNT = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'manageuser/getAllManageUser';
      static CREATE_NEW_USER_URL = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'manageuser/createNewUser';
      static UPDATE_USER_ROLE = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'manageuser/updaterole';
      static UPLOAD_COST_DATA = UrlConstants.SCHOLARLYSTATS_BASE_URL +
            'file/uploadData';

      static BROWSE_COST_DATA_SHEET = UrlConstants.SCHOLARLYSTATS_BASE_URL+'file/uploadDataSheet';
      static COST_DATA_DOWNLOAD_REPORTS = UrlConstants.SCHOLARLYSTATS_BASE_URL +
            'report/download/template/Price_per_Use_Template.xlsx';
      static EXPORT_PLATFORM_ACCESS_DETAILS_DOWNLOAD = UrlConstants.SCHOLARLYSTATS_BASE_URL +
            'platformReport/downloadAllPlatformDetails';
      static EXPORT_SELECTED_PLATFORM_DETAILS = UrlConstants.SCHOLARLYSTATS_BASE_URL +
            'platformReport/downloadAllPlatformWithReportId';
      static EXPORT_RECENT_REPORT_COLLECTION = UrlConstants.SCHOLARLYSTATS_BASE_URL +
            'report/download/platform/recentReportCollectionStatus';
      static C5_SELECTED_PLATFORM_DETAILS = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'platformReport/getC5AllPlatformWithReportId';
      static PLATFORM_COUNT = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'platformReport/getSelectedAndTotalPlatform';

      static SELECTED_PLATFORM_DETAILS_CSV = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'platformReport/getAllPlatformWithReportIdCSV';
      /* **************  ADMINISTRATIVE FEATURES  *************** */

      /* **************  DATA ANALYSIS TOOL  *************** */
      static ZERO_USAGE_CONTRIBUTION = UrlConstants.SCHOLARLYSTATS_BASE_URL +
            'datool/zuj';

      static ZERO_USAGE_CONTRIBUTION_CSV = UrlConstants.SCHOLARLYSTATS_BASE_URL +
            'datool/zujcsv';

      static INCREASE_IN_USAGE_IN_JOURNAL = UrlConstants.SCHOLARLYSTATS_BASE_URL +
            'datool/iuj';

      static INCREASE_IN_USAGE_IN_JOURNAL_CSV = UrlConstants.SCHOLARLYSTATS_BASE_URL +
            'datool/iujcsv';

      static DECLINE_IN_USAGE_IN_JOURNAL = UrlConstants.SCHOLARLYSTATS_BASE_URL +
            'datool/duj';
      static DECLINE_IN_USAGE_IN_JOURNAL_CSV = UrlConstants.SCHOLARLYSTATS_BASE_URL +
            'datool/dujcsv';

      static JOURNAL_CONTRIBUTION = UrlConstants.SCHOLARLYSTATS_BASE_URL +
            'datool/jcp';
      static JOURNAL_CONTRIBUTION_CSV = UrlConstants.SCHOLARLYSTATS_BASE_URL +
            'datool/jcpcsv';
      static MONTHLY_VARIATION_JOURNAL = UrlConstants.SCHOLARLYSTATS_BASE_URL +
            'datool/mvj';
            static MONTHLY_VARIATION_JOURNAL_CSV= UrlConstants.SCHOLARLYSTATS_BASE_URL +
            'datool/mvjcsv'      
      static DENIAL_JOURNAL = UrlConstants.SCHOLARLYSTATS_BASE_URL +
            'datool/dj';

      static CUSTOMIZE_QUERY_TOOL = UrlConstants.SCHOLARLYSTATS_BASE_URL +
            'datool/dynamic_query_tool';
      
            static GET_QUERY_TOOL_REPORTS = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'platformReport/getQueryToolReportType'; 
      static GET_QUERY_TOOL_PLATFORM = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'platformReport/getQueryToolPlatformDetails'; 
      static GET_QUERY_TOOL_COLLECTION_PLATFORM = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'platformReport/getQueryToolCollectionPlatformDetails';      
     static GET_PUBLISHER_NAME = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'platformReport/getQueryToolPublisher';      
     static METRICTYPE = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'platformReport/getQueryToolMetricType'; 
     static QUERY_TOOL_FINAL_REPORT = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'platformReport/getQueryToolFinalReport'; 
     static GET_YOP = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'platformReport/getQueryToolYOP';
     static GET_QUERY_DEFINATIONS = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'platformReport/getQueryToolSearchingQuery';
     static GET_SECTIONTYPE = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'platformReport/getQueryToolSectionType';
     static GET_DATATYPE = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'platformReport/getQueryToolDataType';
     static GET_ACCESSTYPE = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'platformReport/getQueryToolAccessType';
     static GET_ACCESSMETHOD = UrlConstants.SCHOLARLYSTATS_BASE_URL + 'platformReport/getQueryToolAccessMethod';
     
     
     
      /* **************  DATA ANALYSIS TOOL  *************** */

      /* **************  DYNAMIC ALL REPORT DOWNLOAD*************** */
      static DYNAMIC_SELECTED_REPORT_DOWNLOAD = UrlConstants.SCHOLARLYSTATS_BASE_URL +'dynamicReport/getDynamicReportExcel';
      static JSON_SELECTED_REPORT_DOWNLOAD = UrlConstants.SCHOLARLYSTATS_BASE_URL +'reports';



}
