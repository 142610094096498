import { Component, OnInit, AfterViewInit } from '@angular/core';
import { IDGeneratorService } from '../../../services';
import { GraphService } from '../graph.service';
import { GraphCore } from '../graph.core';
declare var Highcharts;

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.css']
})
export class BarChartComponent extends GraphCore implements AfterViewInit {
  NAME_CHART = 'bar-chart';
  constructor(
    protected idGeneratorService: IDGeneratorService,
    protected graphService: GraphService
  ) {
    super(graphService, idGeneratorService);

  }



  initChart() {

    Highcharts.chart(this.id, {
      chart: {
        type: 'column'
      },
      // title: {
      //   text: 'Top Platforms by Access Denial Journals'
      // },
      title: {
        text: '<span style="font-size:15px;">Top Platforms by Access Denial Journals</span>',
        align: 'left',
        style: {
          fontSize: '24px'
        }
      },
      xAxis: {
        categories: ['Project MUSE', 'Cambridge Journals Online', 'Institute of Electrical and Electronics Engineers', 'OvidSP', 'JSTOR']
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Access Denied to Full-text Articles'
        }
      },
      tooltip: {
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
        shared: true
      },
      plotOptions: {
        column: {
          stacking: 'normal'
        }

      },
      series: [{
        name: 'Access denied: concurrent/simultaneous user licence limit exceeded',
        data: [3500, 5100, 400, 7400, 4200],
        color: '#8abe6e'
      }, {
        name: 'Access denied: content item not licenced',
        data: [7200, 6200, 2303, 3200, 4100],
        color: '#aacce7'
      }],
      credits: {
        enabled: false
      },
    });
    //end of chart 3 /
  }

}
