import { OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { GridApi, ColumnApi, GridOptions, ColDef } from 'ag-grid';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/timer';

import { BaseSearchModel, BasicSearchModelI } from './base.search.model';
import { BaseService } from './base.service';
import { SessionObject, ProjectUtils } from '../utility';
import { debounce } from 'rxjs/operator/debounce';

export interface GridAPII {
      gridApi: GridApi;
      columnApi: ColumnApi;
}

export abstract class BaseComponent implements OnInit, OnDestroy, AfterViewInit {

      @ViewChild('baseLoader') baseLoader: any;

      get sessionObject(): SessionObject {
            return ProjectUtils.getSessionObject();
      }
      gridOptions: GridOptions = {};
      gridApi: GridApi;
      columnApi: ColumnApi;
      GRIDPAGESIZE = 50;
      originalApiData: any = null;


      ngOnInit() {
            this.xtBaseOnInit();
      }

      xtBaseOnInit() {
      }

      ngAfterViewInit() {
            this.xtBaseAfterViewInit();

      }
      xtBaseAfterViewInit() {
      }

      constructor(
            protected baseService: BaseService
      ) {
            this.xtBaseConstructor();
            this.initSearchModels();
      }
      xtBaseConstructor() {
      }


      initSearchModels() {
      }

      getSearchModel(name: string) {
            return {};
      }

      setSearchModel(name: string) {
      }


      ngOnDestroy() {
            this.xtBaseOnDestroy();
      }
      xtBaseOnDestroy() {
      }


      setColumnDef(name: string): ColDef | any {
            return [];
      }



      doOnGridReady(name: string) {
            this.xtBaseDoOnGridReady(name);
            const gridAPIS = this.getGridApi(name);
            if (gridAPIS.gridApi) {

                  const gridApi = gridAPIS.gridApi;
                  gridApi.hideOverlay();
                  this.setColumnData(gridApi, name);
                  gridApi.sizeColumnsToFit();
            }
      }
      xtBaseDoOnGridReady(name: string) {
      }



      setColumnData(gridApi: GridApi, name: string) {

            let colData: Array<any> = this.setColumnDef(name);

            colData = colData.map((item) => {
                  return Object.assign({}, item);
            });

            const agDiv = document.getElementsByClassName('ag-layout-normal');
            const gridWidth = agDiv[0].clientWidth - 2;

            let waitTillOne = 0;
            let totalWidth = 0;

            if (gridWidth > 0) {
                  colData.forEach(item => {
                        if (item['minWidth']) {
                              waitTillOne += (gridWidth * item['minWidth']) % 100;
                              item['minWidth'] = parseInt(((gridWidth * item['minWidth']) / 100) + '', 10);
                              totalWidth += item['minWidth'];

                              if (waitTillOne >= 100) {
                                    item['minWidth'] += 1;
                                    waitTillOne %= 100;
                              }

                        }
                  });
            }


            gridApi.setColumnDefs(colData);
      }


      OnSubmit(name: string, bindDataToGrid = true, ...otherGridNames: Array<string>) {

            otherGridNames.forEach((otherName) => {


                  const gridAPIS = this.getGridApi(name);
                  if (gridAPIS.gridApi) {

                        const otherGridApi = gridAPIS.gridApi;
                        otherGridApi.paginationSetPageSize(this.GRIDPAGESIZE);
                        otherGridApi.showLoadingOverlay();
                  }
            });

            const gridAPIS = this.getGridApi(name);
            if (gridAPIS.gridApi) {
                  const gridApi = gridAPIS.gridApi;
                  gridApi.paginationSetPageSize(this.GRIDPAGESIZE);
                  gridApi.showLoadingOverlay();
            }


            this.setSearchModel(name);
            this.loadDataFromApiNSetGrid(name, bindDataToGrid);
      }


      loadDataFromApiNSetGrid(name: string, bindDataToGrid = false) {
            // const paramsBody = this.getParamsBody(name);
            // const url = this.getServiceUrl(name);
            this.showLoader();
            const { url, params } = this.getQueryUrl(name);

            this.baseService.getDataFromAPI(url, params)
                  .subscribe((data) => {
                        this.originalApiData = data;
                        this.scrollToElement(name);
                        data = this.filterDataFromDataKey(data);

                        this.xtBaseLoadDataFromApiProcessData(name, data);
                        if (bindDataToGrid) {
                              this.bindDataToGrid(name, data);
                        }
                        this.hideLoader();
                        //  this.autoSizeColumns(name);
                  }, (e) => {
                        this.hideLoader();
                  });
      }

      bindDataToGrid(name: string, data: Array<any>) {

            const gridAPIS = this.getGridApi(name);
            if (gridAPIS.gridApi) {
                  const gridApi = gridAPIS.gridApi;
                  gridApi.setRowData(data);
            }

      }

      filterDataFromDataKey(data: any) {
            if (data['data']) {
                  return data['data'];
            } else {
                  return data;
            }
      }

      loadDataFromApi(name: string): Observable<any> {
            //  const paramsBody = this.getParamsBody(name);
            //  const url = this.getServiceUrl(name);

            const { url, params } = this.getQueryUrl(name);
            this.scrollToElement(name);
            return this.baseService.getDataFromAPI(url, params);

            // Old One
            // return this.baseService.getDataFromAPI(url, paramsBody);
      }

      autoSizeColumns(name: string) {
            // var allColumnIds = [];
            // this.gridColumnApi.getAllColumns().forEach(function(column) {
            //   allColumnIds.push(column.colId);
            // });
            // this.gridColumnApi.autoSizeColumns(allColumnIds);
            const gridAPIS = this.getGridApi(name);
            if (gridAPIS.gridApi) {


                  const columnApi = gridAPIS.columnApi;
                  const gridApi = gridAPIS.gridApi;
                  const allColumnIds = [];
                  columnApi.getAllColumns()
                        .forEach(function (column: any) {
                              allColumnIds.push(column.colId);
                        });


                  if (gridApi.getDisplayedRowCount() !== 0) {
                        const timer = Observable.timer(50, 100);
                        const timerSub = timer.subscribe((data) => {

                              if (data === 5) {
                                    timerSub.unsubscribe();
                              }
                              columnApi.autoSizeColumns(allColumnIds);
                              columnApi.sizeColumnsToFit(ProjectUtils.GRIDWIDTH)
                        });
                  }

            }
            //    gridApi.sizeColumnsToFit();
      }

      xtBaseLoadDataFromApiProcessData(name: string, data: any | Array<any>): any | Array<any> {
            return null;
      }

      getGridApi(name: string): GridAPII {
            return {
                  gridApi: this.gridApi,
                  columnApi: this.columnApi
            };
      }

      getServiceUrl(name: string): string {
            return null;
      }

      // getParamsBody(name: string): any {
      //       return null;
      // }


      getValueFromSearchModel(name: string): any {
            const searchModel = this.getSearchModel(name) || {};
            const retValue = {};
            const keys = Object.keys(searchModel);
            keys.forEach((key) => {
                  retValue[key] = searchModel[key].value;
            });
            return retValue;
      }

      setValueFromSession(searchModel: any, sessionModel: any) {
            const keys = Object.keys(sessionModel || {});
            keys.forEach((key) => {
                  searchModel[key].value = sessionModel[key];
            });
      }

      getParamsBody(name: string) {
            //debugger;
            const searchModel = this.getSearchModel(name) || {};
            let retValue = '';
            const keys = Object.keys(searchModel);
            keys.forEach((key) => {
                  const val: BasicSearchModelI = searchModel[key];
                  retValue += '&' + encodeURIComponent(val.apiKey) + '=' + encodeURIComponent(val.value);
            });
            retValue = retValue.substring(1);
            return retValue;
      }



      getQueryParams(name: string): string {
//debugger;
            const searchModel = this.getSearchModel(name) || {};
            let retValue = '';
            const keys = Object.keys(searchModel);
            keys.forEach((key) => {

                  const val: BasicSearchModelI = searchModel[key];
                  if (Array.isArray(val.value)) {
                        retValue += '&' + encodeURIComponent(val.apiKey) + '=' + encodeURIComponent((val.value).join(','));
                  } else {
                        retValue += '&' + encodeURIComponent(val.apiKey) + '=' + encodeURIComponent(val.value);
                  }

            });
            retValue = retValue.substring(1);
            const nonSearchModel = this.getNonSearchModelParams(name);
            
            return retValue + nonSearchModel;
          
      }


      getNonSearchModelParams(name?: string): string {
            return '';
      }


      getQueryUrl(name: string) {
            const url = this.getServiceUrl(name);
            const params = this.getQueryParams(name);
            
            return {
                  url: url,
                  params: params
            };
      }


      doOnTabChange(name: string) {
            this.xtBaseDoOnTabChange(name);
            this.autoSizeColumns(name);
      }

      xtBaseDoOnTabChange(name: string) {
      }


      hideLoader(...loaders: Array<| any>) {
            if (this.baseLoader) {
                  this.baseLoader.show = false;
            }
            if (Array.isArray(loaders)) {
                  loaders.forEach((loader) => {
                        loader.show = false;
                  });
            }
      }

      showLoader(...loaders: Array<any>) {
            if (this.baseLoader) {
                  this.baseLoader.show = true;
            }

            if (Array.isArray(loaders)) {
                  loaders.forEach((loader) => {
                        loader.show = true;
                  });
            }
      }


      createDynamicColumns(obj: any, name: any) {

            const columns: Array<ColDef> = [];
            const keys = Object.keys(obj || {});

            const len = keys.length;

            let width = 10;
            width = 100 / len;
            keys.forEach((key) => {
                  columns.push({
                        headerName: key.split('_').join(' '),
                        field: key,
                        minWidth: ProjectUtils.ag_SetWidth(width),
                        cellRenderer: ProjectUtils.limitStringNAddToolTip
                  });
                  this.hideLoader();
            }, (e) => {
                  this.hideLoader();
            });

            const gridAPIS = this.getGridApi(name);
            if (gridAPIS.gridApi) {
                  const gridApi = gridAPIS.gridApi;
                  gridApi.paginationSetPageSize(this.GRIDPAGESIZE);
                  gridApi.hideOverlay();
                  gridApi.setColumnDefs(columns);
                  gridApi.sizeColumnsToFit();
            }
      }

      getScrollCordinates(name: string) {
            return {
                  x: null,
                  y: null
            };
      }

      scrollToElement(name: string) {
            const scrollCordinates = this.getScrollCordinates(name);
            ProjectUtils.html.scrollToCordinates(scrollCordinates.x, scrollCordinates.y);
      }

      setDropDownComponentValue(keyName: string) {


            const comboData = this.getSearchModel('')[keyName];
            if (!ProjectUtils.isEmpty(comboData)) {
                  return ProjectUtils.getIdAsArray(comboData);
            } else {
                  return [];
            }
      }



}


