import { Component, OnInit, Input } from '@angular/core';

import { IDGeneratorService } from '../../../services';
import { GraphService } from '../graph.service';
import { GraphCore } from '../graph.core';

import { SessionObject, ProjectUtils, UrlConstants } from '../../../utility';


declare var Highcharts;
@Component({
  selector: 'app-ring-chart',
  templateUrl: './ring-chart.component.html',
  styleUrls: ['./ring-chart.component.css']
})
export class RingChartComponent extends GraphCore {

  NAME_CHART = 'ring';
  @Input() grftype;
  textAlign = 50;
  queryString:String;c
  constructor(
    protected idGeneratorService: IDGeneratorService,
    protected graphService: GraphService
  ) {
    super(graphService, idGeneratorService);
  }

  downloadLowPlatformChart(){
    if (this.grftype !== 'lowUsages') {
    const childdetail = ProjectUtils.getSessionObject().consortiaChildUserID;
    if(childdetail != 'consortiaAdmin'){
          this.queryString = childdetail;
    }else{
          this.queryString = ProjectUtils.getSessionObject().userDetails.userID;
    }
    
    
        const url = `${UrlConstants.DASHBOARD_GRAPH_HIGHEST_LOW_USAGESTATSCSV}?user_id=${this.queryString}`;
        console.log('Download_URL', url);
        ProjectUtils.downloadGET(url);
  }
      }
 



  initChart(data: any) {
    this.textAlign = (this.grftype) ? -50 : -110;
    const self = this;
    const title = '';
    console.log('ring chart', data);
    // debugger
    if (this.grftype !== 'lowUsages') {



      this.title = 'Top 10 Platforms with Low usages stats Between 1-10';
      this.getSum(data, 'count');

      this.graphData = data.map((item) => {
        return {
          name: item['mpsCollectionPlatform'],
          y: item['count'],
          z: 118.9,
        };
      });
    } else {

      this.title = 'Zero Usages %';

      this.graphData = [];
      Object.keys(data)
        .forEach((key) => {
          this.graphData.push({ name: key, y: parseFloat(data[key]), z: 118.9 });
        });
      this.getSum(this.graphData, 'y');
    }
    console.log(this.graphData);
    Highcharts.chart(this.id, {
      chart: {
        type: 'variablepie'
      },

      title: {
        text: '<span style="font-size:15px;">' + this.title + '</span>',
        align: 'left',
        style: {
          fontSize: '24px'
        }
      },
      subtitle: {
        text: '<span class="centerTxt">Successful<br> Full-text Requests </span>',
        align: 'center',
        floating: true,
       // verticalAlign: 'middle',
      //  x: this.textAlign,
        useHTML: true
      },
      tooltip: {
        formatter: function () {
          return '<span style="color:' + this.point.color + '>\u25CF</span> <b> ' + this.point.name + '</b><br/>' +
            'Count: <b>' + self.getPercentage(this.point.y) + '</b><br/>';
        },
        headerFormat: '',
        pointFormat: ''
        // '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
        //   'Contribution: <b>{point.y}</b><br/>'
        // 'Population density (people per square km): <b>{point.z}</b><br/>'
      },

      plotOptions: {
        variablepie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: true,
        }
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
        itemMarginTop: 2,
        itemMarginBottom: 2
      },
      series: [{
        minPointSize: 5,
        innerSize: '80%',
        zMin: 0,
        name: 'countries',
        data: this.graphData



        // [{
        //   name: 'Spain',
        //   y: 505370,
        //   z: 118.9,
        //   color: '#e58097'
        // }, {
        //   name: 'France',
        //   y: 505370,
        //   z: 118.9,
        //   color: '#86bd77'
        // }, {
        //   name: 'Poland',
        //   y: 212685,
        //   z: 118.9,
        //   color: '#8fcccd'
        // }, {
        //   name: 'Czech Republic',
        //   y: 212685,
        //   z: 118.9,
        //   color: '#75babb'
        // }, {
        //   name: 'Italy',
        //   y: 212685,
        //   z: 118.9,
        //   color: '#f9e27a'
        // }]




      }],
      credits: {
        enabled: false
      }
    });
  }

}



