import { Component, OnInit, Input } from '@angular/core';

import { environment } from '../../../../../environments/environment.prod';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {


  @Input() desktop: boolean;
  @Input() login: boolean;


  version = environment.version;

  constructor() { }

  ngOnInit() {
  }

}
