

export class MonthYearModel {
      SelectedMonth?: string;
      MonthFormat = 'MM, yy';
      MinMonth?: number;
      MaxMonth?: number;
      constructor(minMonth?: number, maxMonth?: number) {
            this.MinMonth = minMonth;
            this.MaxMonth = maxMonth;
      }

}
