import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { RootSharedModule } from './acore/sharedModules';
import { AppComponent } from './app.component';
import { routing } from './app.routing';
import { UtilityModule } from './acore/utility';
import { ServiceModule } from './acore/services';
import { GuardModules } from './acore/guards';
import { AgGridModule } from 'ag-grid-angular';
//import 'ag-grid-enterprise';



import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AGModule } from './acore/components/ag-component';
import { ComponentModule } from './acore/components';
//import { AdminConsortiaComponent } from './pages/consortia/admin-consortia/admin-consortia.component';
//import { ForgetPasswordComponent } from './forget-password/forget-password.component';

@NgModule({
  declarations: [
    AppComponent,
    //AdminConsortiaComponent,
    
  ],
  imports: [
    AgGridModule.withComponents([]),
    routing,
    BrowserModule,
    FormsModule,
    RootSharedModule,
    AGModule,
    UtilityModule,
    ServiceModule,
    GuardModules,
    ComponentModule,
    BrowserAnimationsModule,
  //  ForgetPasswordComponent
  ],
  providers: [
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
