import { GraphService } from './graph.service';
import { IDGeneratorService } from '../../services';
import {
      OnInit, OnChanges, OnDestroy,
      AfterViewInit, Input, ViewChild,
      ElementRef
} from '@angular/core';
import { LoaderComponent } from '../loader/loader.component';
import { ProjectUtils } from '../../utility';

interface ChartsI {
      NAME_CHART: string;
}

export class GraphCore implements OnInit, OnChanges, OnDestroy, ChartsI, AfterViewInit {

      @ViewChild('graphLoader', { read: LoaderComponent }) graphLoader: LoaderComponent;

      NAME_CHART = 'charts';
      id: string;

      myAfterViewInit: boolean;

      @Input() url;
      @Input() params;
      @Input() title;

      graphData: Array<any>;

      dataSum: number;

      constructor(
            protected graphService: GraphService,
            protected idGeneratorService: IDGeneratorService
      ) {
            this.id = this.idGeneratorService.generateNormalIDs(this.NAME_CHART);
      }


      ngOnInit() {

      }


      getLoader = (): LoaderComponent => {
            return this.graphLoader;
      }

      ngOnChanges() {
            if (this.url && this.myAfterViewInit) {
                  this.loadData();
            }

      }

      getSum(data: Array<any>, key: string) {
            let sum = 0;
            data.forEach((item) => {
                  sum += parseFloat(item[key]);
            });
            this.dataSum = sum;
      }

      getPercentage = (value) => {
            const perc = (value * 100) / this.dataSum;
            return ProjectUtils.str_ToBoldString(ProjectUtils.toFixedIfNumber(perc) + '%');
      }


      getPercentageWithOutBold = (value) => {
            const perc = (value * 100) / this.dataSum;
            return ProjectUtils.toFixedIfNumber(perc);
      }

      loadData() {
            this.graphService.getGraphData(this.url, '')

                  .subscribe((data) => {

                        this.graphLoader.show = false;
                        this.initChart(data);
                        console.log(data);
                  });
      }

      ngAfterViewInit() {
            console.log(this.NAME_CHART);
            this.loadData();
      }

      initChart(data?: any) {

      }

      ngOnDestroy() {

      }


}
