import {
  Component, OnInit, AfterViewInit
} from '@angular/core';

import { IDGeneratorService } from '../../../services';
import { GraphService } from '../graph.service';
import { GraphCore } from '../graph.core';

declare var Highcharts;


@Component({
  selector: 'app-circular-lines',
  templateUrl: './circular-lines.component.html',
  styleUrls: ['./circular-lines.component.css']
})
export class CircularLinesComponent extends GraphCore implements AfterViewInit {
  NAME_CHART = 'circular-lines';
  finaldata = [];
  outerY: any;
  innerY: any;
  constructor(
    protected idGeneratorService: IDGeneratorService,
    protected graphService: GraphService
  ) {
    super(graphService, idGeneratorService);
  }


  renderIcons = () => {

  }

  initChart(resultdata: any) {

    this.getSum(resultdata, 'value');
    resultdata.forEach(element => {
      element.name = element.key;
      const str = element.key.substring(0, 13);
      if (element.key.length > 13) {
        element.nameRest = str + '...';
      } else {
        element.nameRest = str;
      }

      // this.outerY = this.getPercentageWithOutBold(resultdata[0].value);
      // this.innerY = this.getPercentageWithOutBold(resultdata[1].value);
      // element.innerRadius = this.getPercentageWithOutBold(element.value);
      // element.outerRadius = this.getPercentageWithOutBold(element.value) + 7;

      // element.innerRadius = element.innerRadius + '%';
      // element.outerRadius = element.outerRadius + '%';
      element.y = parseFloat(this.getPercentageWithOutBold(element.value));
      delete element.key;
      delete element.value;
      // console.log(element);
    });

    console.log(resultdata);
    Highcharts.chart(this.id, {

      chart: {
        type: 'solidgauge',

        events: {
          render: this.renderIcons
        }
      },
      credits: {
        enabled: false
      },

      title: {
        text: '<span style="font-size:15px;">Top 10 Platforms Contribution vs. Other Platforms-Full Text Requests</span>',
        align: 'left',
        style: {
          fontSize: '24px'
        }
      },

      tooltip: {
        borderWidth: 0,
        backgroundColor: 'none',
        shadow: false,
        style: {
          fontSize: '16px'
        },
        pointFormat: '<div style="text-align:center;display:block">{series.name}<br><span style="font-size:2em;text-align:center;color: {point.color}; font-weight: bold;display:block;text-align:center;">{point.y}%</span></div>',
        positioner: function (labelWidth) {
          return {
            x: (this.chart.chartWidth - labelWidth) / 2,
            y: (this.chart.plotHeight / 2) + 15
          };
        }
      },

      pane: {
        startAngle: 0,
        endAngle: 360,
        background: [{ // Track for Move
          outerRadius: '107%',
          innerRadius: '100%',
          backgroundColor: '#eeeeee',
          borderWidth: 0
        }, { // Track for Exercise
          outerRadius: '82%',
          innerRadius: '75%',
          backgroundColor: '#eeeeee',
          borderWidth: 0
        },
          // { // Track for Stand
          //   outerRadius: '54%',
          //   innerRadius: '48%',
          //   backgroundColor: '#eeeeee',
          //   borderWidth: 0
          // }
        ]
      },

      yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: []
      },

      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: false
          },
          linecap: 'round',
          stickyTracking: false,
          rounded: true
        }
      },

      series: [{
        marker: {
          symbol: 'circle',
          fillColor: '#476ef1',
        },
        lineWidth: 0,
        showInLegend: true,
        //name: resultdata[0].name,
        // name: resultdata[0].nameRest,     Changes the corresponding text with below text
        name: 'Top Platforms Contribution',
        data: [{
          //  color: Highcharts.getOptions().colors[0],
          color: '#476ef1',
          radius: '107%',
          innerRadius: '100%',
          y: resultdata[0].y
        }]
      }, {
        marker: {
          symbol: 'circle',
          fillColor: '#f9e27a',
        },
        lineWidth: 0,
        showInLegend: true,
        //name: resultdata[1].name,
        // name: resultdata[1].nameRest,     Changes the corresponding text with below text
        name: 'Usages Stats% from remaining Platforms',
        data: [{
          // color: Highcharts.getOptions().colors[1],
          color: '#f9e27a',
          radius: '82%',
          innerRadius: '75%',
          y: resultdata[1].y
        }]
      },
      ],

    });






  }


}







