
import { ColDef, GridApi, ColumnApi } from 'ag-grid';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/timer';
import { HtmlUtil } from './html.util';

export interface GridAPII {
      gridApi: GridApi;
      columnApi: ColumnApi;
}





export class Grid {




      autoSizeColumns(gridAPIS: GridAPII) {

            if (gridAPIS.gridApi) {
                  const columnApi = gridAPIS.columnApi;
                  const gridApi = gridAPIS.gridApi;
                  const allColumnIds = [];
                  columnApi.getAllColumns()
                        .forEach(function (column: any) {
                              allColumnIds.push(column.colId);
                        });


                  if (gridApi.getDisplayedRowCount() !== 0) {



                        const timer = Observable.timer(50, 100);
                        const timerSub = timer.subscribe((data) => {

                              if (data === 5) {
                                    timerSub.unsubscribe();
                              }
                              columnApi.autoSizeColumns(allColumnIds);
                        });
                  }

            }
            //    gridApi.sizeColumnsToFit();
      }



}
