
export interface BasicSearchModelI {
      value: any | string | Array<any>;
      defaultValue?: any | string | Array<any>;
      label: string;
      apiKey: string;
      placeholder?: string;
      name?: string;
}


export class BaseSearchModel {
      constructor() {

      }
}
