export const defaultCssUrl = '/assets/css/default-theme.css';

export const defaultTheme = {
      colors: ['#7cb5ec', '#434348', '#90ed7d', '#f7a35c', '#8085e9', '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1'],
      chart: {
            backgroundColor: '#ffffff',
            style: {
                  //fontFamily: 'Dosis, sans-serif'
            },
            plotBorderColor: '#cccccc'
      },
      title: {
            style: {
                  fontSize: '16px',
                  fontWeight: 'bold',
                  textTransform: 'uppercase'
            }
      },
      tooltip: {
            borderWidth: 0,
            backgroundColor: 'rgba(247,247,247,0.85)',
            shadow: false,
            style: {
                  color: '#333333',
                  cursor: 'default',
                  fontSize: '12px',
                  pointerEvents: 'none',
                  whiteSpace: 'nowrap'
            }
      },
      legend: {
            itemStyle: {
                  fontWeight: 'bold',
                  fontSize: '13px'
            }
      },
      xAxis: {
            gridLineWidth: 1,
            labels: {
                  style: {
                        fontSize: '12px'
                  }
            }
      },
      yAxis: {
            minorTickInterval: 'auto',
            title: {
                  style: {
                        textTransform: 'uppercase'
                  }
            },
            labels: {
                  style: {
                        fontSize: '12px'
                  }
            }
      },
      plotOptions: {
            candlestick: {
                  lineColor: '#404048'
            }
      },


      // General
      background2: '#F0F0EA'

};
