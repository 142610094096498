import {
  Component, OnInit, AfterViewInit, ViewChild, Input,
  forwardRef,
} from '@angular/core';

import {
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS
} from '@angular/forms';

import { IDGeneratorService } from '../../services';
import { CustomFormControl } from '../core';
import { MonthYearModel } from './month-year.model';
import { ProjectUtils } from '../../utility';
import { Moment } from 'moment';

declare var $;

const MY_NG_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MonthYearComponent),
  multi: true,
};

const MY_NG_VALIDATORS = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => MonthYearComponent),
  multi: true,
};

@Component({
  selector: 'app-month-year',
  templateUrl: './month-year.component.html',
  styleUrls: ['./month-year.component.css'],
  providers: [
    MY_NG_VALUE_ACCESSOR,
    MY_NG_VALIDATORS]
})
export class MonthYearComponent extends CustomFormControl implements OnInit, AfterViewInit {

  @Input() monthYearModel: MonthYearModel;
  @Input() placeholder: string = null;
  id: string;

  constructor(
    private idGen: IDGeneratorService
  ) {
    super();
    this.id = idGen.generateNormalIDs('month-year');
    this.monthYearModel = new MonthYearModel();
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.initMonthPicker();
  }

  initMonthPicker() {

    const basicSetting = {
      // UseInputMask: true,
      Button: false,
      OnAfterChooseMonth: this.onChange
    };

    Object.assign(this.monthYearModel, basicSetting);
    $('#' + this.id).MonthPicker(this.monthYearModel);
  }

  onChange = (date: Date) => {
    const year = date.getFullYear();
    let month: any = date.getMonth() + 1;

    if (month < 10) {
      month = '0' + month;
    }

    this.value = month + '-' + year;
    this.propagateChange(this.value);
  }

}
