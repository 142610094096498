import {
  Component, OnInit, AfterViewInit
} from '@angular/core';

import { IDGeneratorService } from '../../../services';
import { GraphService } from '../graph.service';
import { GraphCore } from '../graph.core';

declare var Highcharts;

@Component({
  selector: 'app-semi-cicle-donut',
  templateUrl: './semi-cicle-donut.component.html',
  styleUrls: ['./semi-cicle-donut.component.css']
})
export class SemiCicleDonutComponent extends GraphCore implements AfterViewInit {
  NAME_CHART = 'semi-cicle-donut';
  constructor(
    protected idGeneratorService: IDGeneratorService,
    protected graphService: GraphService
  ) {
    super(graphService, idGeneratorService);
  }



  initChart(resultdata: any) {




    resultdata.forEach(element => {
      element.name = element.key;
      element.y = parseFloat(element.value);
      delete element.key;
      delete element.value;
      // console.log(element);
    });

    Highcharts.chart(this.id, {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false
      },

      title: {
        text: '<span style="font-size:15px;">Top 10 Platforms  Contribution vs. Other Platforms-Full Text Requests</span>',
        align: 'left',
        style: {
          fontSize: '24px'
        }
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            distance: -50,
            style: {
              fontWeight: 'bold',
              color: 'white'
            }
          },
          startAngle: -90,
          endAngle: 90,
          center: ['50%', '75%']
        }
      },
      series: [{
        type: 'pie',
        name: 'Contribution Percentage',
        innerSize: '50%',
        data: resultdata
      }],
      credits: {
        enabled: false
      }
    });
  }

}
