import { Component, OnInit,AfterViewInit } from '@angular/core';
import { IDGeneratorService } from '../../../services';
import { GraphService } from '../graph.service';
import { GraphCore } from '../graph.core';
import { SessionObject, ProjectUtils, UrlConstants } from '../../../utility';
declare var Highcharts;


@Component({
  selector: 'app-year-trend-r5',
  templateUrl: './year-trend-r5.component.html',
  styleUrls: ['./year-trend-r5.component.css']
})
export class YearTrendR5Component extends GraphCore implements AfterViewInit {
  NAME_CHART = 'basic-chart';
  recordData: Array<any>;
  queryString : string;
  recordCheckNull  : string;
  constructor(
    protected idGeneratorService: IDGeneratorService,
    protected graphService: GraphService
  ) {
    super(graphService, idGeneratorService);  
    
  }

  downloadMetricChart(){
const childdetail = ProjectUtils.getSessionObject().consortiaChildUserID;
if(childdetail != 'consortiaAdmin'){
      this.queryString = childdetail;
}else{
      this.queryString = this.sessionObject.userDetails.userID;
}


const monthLive= ProjectUtils.getSessionObject().liveMonth;
    const url = `${UrlConstants.DASHBOARD_GRAPH_MONTH_WISE_METRIC_SUM_CSV}?user_id=${this.queryString}&month=${monthLive}`;
    console.log('Download_URL', url);
    ProjectUtils.downloadGET(url);

  }
  sessionObject = ProjectUtils.getSessionObject();
  aprStats = [];
  augStats= [];
  decStats= [];
  febStats= [];
  janStats= [];
  julStats= [];
  junStats= [];

  marStats= [];
  mayStats= [];
  novStats= [];
  octStats= [];
  septStats= [];
  metricType=[];
  processingMonth:any;
  initChart(resultdata: any) {
   
    this.recordData = resultdata['record'];
if(this.recordData.length==0){
  this.recordCheckNull= 'false';
 // alert(this.recordCheckNull);
}else{
 
  this.recordCheckNull= 'true';
 // alert(this.recordCheckNull);
}

Highcharts.chart(this.id, {
     
  chart: {
      type: 'spline'
  },exporting: {
    buttons: {
      contextButton: {
        menuItems: ['downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadCSV'],
      },
    },
},
  title: {
    style: {
      fontFamily: "'raleway'"
    },
      text: 'R5 Year Trend'
  },
  subtitle: {
      text: ''
  },
xAxis: {
    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

},
yAxis: {
  title: {
    style: {
          fontFamily: "'raleway'"
       },
      text: ''
  }
}, tooltip: {
crosshairs: true,
shared: true
},
plotOptions: {
  spline: {
      marker: {
          radius: 4,
          lineColor: '#666666',
          lineWidth: 1
      }
  }
},

series: this.recordData,
credits: {
  enabled: false
}
});
  }}
