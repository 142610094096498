import { NgModule } from '@angular/core';
import { httpServiceFactory, HttpService } from './custom-http';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { IDGeneratorService } from './id-generator';
import { ChartListService } from './chart-list';
@NgModule({
      declarations: [

      ],
      providers: [
            {
                  provide: HttpService,
                  useFactory: httpServiceFactory,
                  deps: [HttpClient]
            },
            IDGeneratorService,
            ChartListService
      ],
      imports: [
            HttpClientModule
      ]
})

export class ServiceModule { }
